import React, {useCallback, useEffect, useState} from 'react';
import {ActivityIndicator, Platform, StyleSheet, View} from 'react-native';
import _ from 'lodash';
import {Colors, Typography} from 'src/styles';
import {Target} from 'src/models';
import TrialByTrialProgressGraph from './graph';
import TrialByTrialTable from './table';
import {RHSeparator} from 'src/common-components/custom-ui-helpers';
import SelectInput from 'src/hook-form/select-input';
import ViewShot from 'react-native-view-shot';
import {Menu} from 'src/design-system';
import AntDesign from 'react-native-vector-icons/AntDesign';
import {SYNC_SERVER} from 'react-native-dotenv';

const TrialByTrialProgress = ({
  program = {},
  sets = [],
  events = [],
  recentSets = [],
  recentEvents = [],
  sessions = [],
  programSessions = [],
  envs = [],
  targets = [],
  currentTarget,
  setCurrentTarget,
  table = false,
  graph = true,
  showGraphs = true,
  title = null,
  extras = {},
  startDate = '',
  endDate = '',
  sessionMessage = false,
  byDay = false,
  filteredEnvItems = [],
  shouldShowSummary = false,
  summaryOnly = false,
  shouldShowMenu = true,
}: any) => {
  const dropdownTargets = targets.map((_target: Target) => {
    return {
      label: _target.name,
      value: _target.id,
    };
  });

  const setsByTargetId = _.groupBy(sets, 'target.id');
  const recentSetsByTargetId = _.groupBy(recentSets, 'target.id');
  const eventsBySetId = _.groupBy(events, 'set.id');
  const sessionsById = _.keyBy(sessions, 'id');
  const targetById = _.groupBy(targets, 'id');
  const sessionsByDay = _.groupBy(sessions, 'sessionDate');
  const [programData, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const isWeb = Platform.OS === 'web';

  useEffect(() => {
    setCurrentTarget(targets?.[0]?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program]);
  const analyzeSets = useCallback(
    (targetId: string) => {
      const analysis: any = {};
      const analysisState: any = [];
      const dailyAnalysisState: any = [];
      const dailyAnalysis: any = {};
      if (targetId) {
        const sessionSets = _.groupBy(setsByTargetId[targetId], 'session.id');
        for (const sessionId of Object.keys(sessionSets)) {
          const sessionSet = sessionSets[sessionId];
          const sessionDate = sessions.find(
            (sesh: any) => sesh.id === sessionId,
          )?.sessionDate;
          let collectorIds: string[] = [];
          const avg = Math.ceil(
            sessionSet.reduce((totalSum: number, set: any) => {
              const setEvents = eventsBySetId[set?.id];
              let eventCollectors = _.uniq(
                _.map(setEvents, (event: any) => event?.createdBy),
              ).filter((id: string) => id !== '');
              collectorIds.push(...eventCollectors);
              return (
                totalSum +
                Math.ceil(
                  (setEvents?.filter((event: any) => event.value === 'success')
                    .length /
                    setEvents?.length) *
                    100,
                )
              );
            }, 0) / sessionSet.length,
          );
          const average = isNaN(avg) ? 0 : avg;
          const dailySessions = sessionsByDay[sessionDate];
          const sessionIds = _.map(dailySessions, 'id');
          const dailySets = setsByTargetId[targetId].filter((set: any) =>
            sessionIds.includes(set?.sessionId),
          );
          const dailyAvg = Math.ceil(
            dailySets.reduce((totalSum: number, set: any) => {
              const setEvents = eventsBySetId[set?.id];
              return (
                totalSum +
                Math.ceil(
                  (setEvents?.filter((event: any) => event.value === 'success')
                    .length /
                    setEvents?.length) *
                    100,
                )
              );
            }, 0) / dailySets.length,
          );
          const dailyAverage = isNaN(dailyAvg) ? 0 : dailyAvg;
          collectorIds = _.uniq(collectorIds);
          let consecutiveSessions = false;
          let state;
          if (analysisState.length === 0) {
            if (program?.baseline) {
              if (
                program?.baselineSessions === 1 &&
                average >= program?.baselineProbes
              ) {
                state = 'completed';
              } else {
                state = 'baseline';
              }
            } else {
              if (
                average >= program?.masteryValue &&
                program?.masterySessions === 1 &&
                !program?.masteryStaff
              ) {
                state = 'completed';
              } else {
                state = 'in-treatment';
              }
            }
          } else if (
            analysisState[analysisState.length - 1]?.state === 'baseline'
          ) {
            if (analysisState.length === program?.baselineSessions) {
              if (
                program?.masterySessions === 1 &&
                !program?.masteryStaff &&
                average >= program?.masteryValue
              ) {
                state = 'completed';
              } else {
                state = 'in-treatment';
              }
            } else if (analysisState.length + 1 === program?.baselineSessions) {
              //check if current average passes
              if (average >= program?.baselineProbes) {
                for (let i = analysisState.length - 1; i >= 0; i--) {
                  if (analysisState[i].average < program?.baselineProbes) {
                    break;
                  }

                  if (
                    analysisState.length - i >=
                    program?.baselineSessions - 1
                  ) {
                    consecutiveSessions = true;
                    break;
                  }
                }
              }
              if (
                consecutiveSessions ||
                (program?.masterySessions === 1 &&
                  average >= program?.masteryValue &&
                  !program.masteryStaff)
              ) {
                state = 'completed';
              } else {
                state = 'in-treatment';
              }
            } else {
              state = 'baseline';
            }
          } else if (
            analysisState[analysisState.length - 1]?.state === 'in-treatment'
          ) {
            let masterySessions = program?.masterySessions;
            let completedSessions = 0;
            if (program?.masteryStaff) {
              masterySessions =
                program?.masterySessions * program?.masteryStaffMinimum;
            }
            for (let i = analysisState.length - 1; i >= 0; i--) {
              if (analysisState[i].state !== 'in-treatment') {
                break;
              } else {
                completedSessions = completedSessions + 1;
              }
            }

            if (completedSessions + 1 >= masterySessions) {
              consecutiveSessions = false;
              let staffMembers: any[] = [];
              if (average < program?.masteryValue) {
                state = 'in-treatment';
              } else {
                if (program?.masteryStaff) {
                  for (let i = analysisState.length - 1; i >= 0; i--) {
                    if (
                      !staffMembers.some(user =>
                        analysisState[i]?.collectors.includes(user),
                      )
                    ) {
                      analysisState[i].collectors.forEach((element: any) => {
                        staffMembers.push(element);
                      });
                    }
                  }

                  if (!staffMembers.some(user => collectorIds.includes(user))) {
                    collectorIds.forEach(collector => {
                      staffMembers.push(collector);
                    });
                  }
                  if (staffMembers.length >= program?.masteryStaffMinimum) {
                    let staffMasteredArray: any[] = [];
                    for (const staff of staffMembers) {
                      let staffAnalysis = analysisState.filter(element =>
                        element.collectors.includes(staff),
                      );
                      for (let i = staffAnalysis.length - 1; i >= 0; i--) {
                        if (staffAnalysis[i].state !== 'in-treatment') {
                          break;
                        }
                        if (staffAnalysis[i].average < program?.masteryValue) {
                          break;
                        }

                        if (
                          staffAnalysis.length - i >=
                          program?.masterySessions
                        ) {
                          staffMasteredArray.push(staff);
                          break;
                        }
                      }
                    }

                    if (
                      staffMasteredArray.length >= program?.masteryStaffMinimum
                    ) {
                      state = 'completed';
                    } else if (
                      staffMasteredArray.length + 1 ===
                      program?.masteryStaffMinimum
                    ) {
                      const currentStaffAnalysis = analysisState.filter(
                        element => element.collectors.includes(collectorIds[0]),
                      );

                      if (
                        !staffMasteredArray.includes(collectorIds[0]) &&
                        currentStaffAnalysis.length + 1 ===
                          program?.masterySessions
                      ) {
                        state = 'completed';
                      } else {
                        state = 'in-treatment';
                      }
                    } else {
                      state = 'in-treatment';
                    }
                  } else {
                    state = 'in-treatment';
                  }
                } else {
                  let consecutiveSessionCount = 0;
                  for (let i = analysisState.length - 1; i >= 0; i--) {
                    if (analysisState[i].state !== 'in-treatment') {
                      break;
                    }
                    if (analysisState[i].average < program?.masteryValue) {
                      break;
                    } else {
                      consecutiveSessionCount = consecutiveSessionCount + 1;
                    }
                  }
                  if (consecutiveSessionCount + 1 >= program?.masterySessions) {
                    state = 'completed';
                  } else {
                    state = 'in-treatment';
                  }
                }
              }
            } else {
              state = 'in-treatment';
            }
          } else if (
            analysisState[analysisState.length - 1]?.state === 'in-maintenance'
          ) {
            let allowance = 0;
            consecutiveSessions = false;
            for (let i = analysisState.length - 1; i >= 0; i--) {
              if (analysisState[i].state !== 'in-maintenance') {
                break;
              }
              if (analysisState[i].average < program?.maintenanceValue) {
                allowance = allowance + 1;
              }

              if (analysisState.length - i >= program?.maintenanceSessions) {
                consecutiveSessions = true;
                break;
              }
            }
            if (
              consecutiveSessions &&
              allowance <= program?.maintenanceAllowance
            ) {
              state = 'completed';
            } else if (
              consecutiveSessions &&
              allowance >= program?.maintenanceAllowance
            ) {
              state = 'in-treatment';
            } else {
              state = 'in-maintenance';
            }
          } else if (
            analysisState[analysisState.length - 1]?.state === 'completed'
          ) {
            if (program?.maintenance) {
              state = 'in-maintenance';
              switch (program?.maintenanceCadence) {
                case 'daily':
                  state = 'in-maintenance';
                  break;
                case 'weekly':
                  const days = [
                    'Sunday',
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                  ];
                  const dayNum = new Date(
                    analysisState[analysisState.length - 1].startTimestamp,
                  ).getDay();
                  const day = days[dayNum];
                  if (day === program?.maintenanceCadenceWeekly) {
                    state = 'in-maintenance';
                  } else {
                    state = 'completed';
                  }
                  break;
                case 'monthly':
                  const date = new Date(
                    analysisState[analysisState.length - 1].startTimestamp,
                  ).getDate();
                  if (date === program?.maintenanceCadenceMonthly) {
                    state = 'in-maintenance';
                  } else {
                    state = 'completed';
                  }
                  break;
              }
              if (
                state === 'in-maintenance' &&
                program?.maintenanceAllowance === 0 &&
                average < program?.maintenanceValue &&
                (program?.maintenanceSessions === 1 ||
                  program?.maintenanceCadence === 'daily')
              ) {
                state = 'in-treatment';
              }
            } else {
              state = 'completed';
            }
          } else {
            state = 'in-treatment';
          }
          const sessionObject = {
            sessionDate,
            dailyAverage,
            sessionId,
            average,
            state,
            id: sessionId,
            startTimestamp: sessionsById[sessionId]?.date
              ? sessionsById[sessionId]?.date
              : sessionsById[sessionId]?.startTimestamp,
            collectors: collectorIds,
          };
          analysisState.push(sessionObject);
          analysis[sessionId] = sessionObject;
        }
        const targetDailyAnalysis = _.groupBy(analysisState, 'sessionDate');
        for (const sessionDay of Object.values(targetDailyAnalysis)) {
          const dailySessionObject = {
            sessionDate: sessionDay[sessionDay.length - 1]?.sessionDate,
            sessionId: sessionDay[sessionDay.length - 1]?.sessionId,
            average: sessionDay[sessionDay.length - 1]?.dailyAverage,
            timestamp: sessionDay[sessionDay.length - 1]?.date,
            startTimestamp: sessionDay[sessionDay.length - 1]?.date
              ? sessionDay[sessionDay.length - 1]?.date
              : sessionDay[sessionDay.length - 1]?.startTimestamp,
            collectors: sessionDay[sessionDay.length - 1]?.collectors,
            state: sessionDay[sessionDay.length - 1]?.state,
          };
          dailyAnalysisState.push(dailySessionObject);
          dailyAnalysis[sessionDay[sessionDay.length - 1]?.sessionDate] =
            dailySessionObject;
        }
      }

      return byDay ? dailyAnalysis : analysis;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setsByTargetId, eventsBySetId, program],
  );
  useEffect(() => {
    if (!isWeb && sessionMessage === false) {
      const analyzeMobilePrograms = async () => {
        setIsLoading(true);
        try {
          const response = await fetch(
            `${SYNC_SERVER}/calculate-reports?programId=${program.id}` +
              (currentTarget ? `&targetId=${currentTarget}` : ''),
            {
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            },
          );

          if (response.status === 200) {
            const value = await response.json();
            setData(value);
          } else {
            setData(null);
          }
        } catch (e) {
          console.log('fetch error:', e);
          setData(null);
        } finally {
          setIsLoading(false);
        }
      };

      analyzeMobilePrograms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTarget]);

  return (
    <>
      {isLoading ? (
        <>
          <ActivityIndicator size={'large'} style={[styles.pt_20]} />
        </>
      ) : (
        <View style={showGraphs ? styles.mt_20 : {}}>
          {showGraphs ? (
            <>
              {graph && (isWeb || (!isWeb && sessionMessage === true)) ? (
                <TrialByTrialProgressGraph
                  program={program}
                  data={analyzeSets(currentTarget)}
                  sets={recentSetsByTargetId?.[currentTarget] || []}
                  sessions={programSessions}
                  allSets={recentSets}
                  recentSetsByTargetId={recentSetsByTargetId}
                  envs={envs}
                  filteredEnvItems={filteredEnvItems}
                  title={title}
                  extras={extras}
                  startDate={startDate}
                  endDate={endDate}
                  byDay={byDay}
                  shouldShowSummary={shouldShowSummary}
                  summaryOnly={summaryOnly}
                  targets={targets}
                  analyzeSets={analyzeSets}
                  shouldShowMenu={shouldShowMenu}
                  target={targetById?.[currentTarget]?.[0]}>
                  {!summaryOnly ? (
                    <SelectInput
                      label={'Target'}
                      items={dropdownTargets}
                      onChange={setCurrentTarget}
                      value={currentTarget}
                      error={undefined}
                    />
                  ) : null}
                </TrialByTrialProgressGraph>
              ) : programData ? (
                <TrialByTrialProgressGraph
                  program={program}
                  data={programData}
                  sets={recentSetsByTargetId?.[currentTarget] || []}
                  sessions={programSessions}
                  allSets={recentSets}
                  recentSetsByTargetId={recentSetsByTargetId}
                  envs={envs}
                  filteredEnvItems={filteredEnvItems}
                  title={title}
                  extras={extras}
                  startDate={startDate}
                  endDate={endDate}
                  byDay={byDay}
                  shouldShowSummary={shouldShowSummary}
                  summaryOnly={summaryOnly}
                  targets={targets}
                  analyzeSets={analyzeSets}
                  shouldShowMenu={shouldShowMenu}
                  target={targetById?.[currentTarget]?.[0]}>
                  {!summaryOnly ? (
                    <SelectInput
                      label={'Target'}
                      items={dropdownTargets}
                      onChange={setCurrentTarget}
                      value={currentTarget}
                      error={undefined}
                    />
                  ) : null}
                </TrialByTrialProgressGraph>
              ) : null}
              {table ? (
                <>
                  {!graph ? (
                    <>
                      <SelectInput
                        label={'Target'}
                        items={dropdownTargets}
                        onChange={setCurrentTarget}
                        value={currentTarget}
                        error={undefined}
                      />
                      <RHSeparator height={16} />
                    </>
                  ) : null}

                  <View style={[styles.pt_20, styles.zIndexNegative]}>
                    {extras?.showExport ? (
                      <View
                        style={[
                          styles.paddingVertical,
                          styles.paddingHorizontal,
                          styles.alignSelfEnd,
                        ]}>
                        <Menu
                          anchor={<AntDesign size={20} name="download" />}
                          options={extras?.tableOptions}
                        />
                      </View>
                    ) : null}
                    <ViewShot ref={extras?.tableExportRef}>
                      {extras?.showExport ? extras?.title : null}
                      <TrialByTrialTable
                        program={program}
                        events={sessionMessage ? recentEvents : events}
                        target={targetById?.[currentTarget]?.[0]}
                        sets={recentSetsByTargetId?.[currentTarget] || []}
                      />
                    </ViewShot>
                  </View>
                </>
              ) : null}
            </>
          ) : (
            <>
              {targets.map((_target: Target) => {
                if ([analyzeSets(_target.id)].length) {
                  return (
                    <View style={styles.pt_20} key={_target.id}>
                      <TrialByTrialTable
                        program={program}
                        events={sessionMessage ? recentEvents : events}
                        target={targetById[_target.id][0]}
                        sets={
                          sessionMessage
                            ? recentSetsByTargetId?.[_target.id] || []
                            : setsByTargetId?.[_target.id] || []
                        }
                      />
                    </View>
                  );
                }
              })}
            </>
          )}
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  pt_20: {
    paddingTop: 20,
  },
  mt_20: {
    marginTop: 20,
  },
  flexRow: {
    flexDirection: 'row',
  },
  alignItemCenter: {
    alignItems: 'center',
  },
  justifySpaceBetween: {
    justifyContent: 'space-between',
  },
  backgroundBlack: {
    backgroundColor: Colors.RAVEN_BLACK,
  },
  backgroundTransparent: {
    backgroundColor: 'transparent',
  },
  mr_10: {
    marginRight: 10,
  },
  emptyContainer: {
    flex: 1,
    minHeight: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyTitle: {
    ...Typography.P3,
    color: Colors.TEXT_PRIMARY,
    marginTop: 8,
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  paddingVertical: {
    paddingVertical: 20,
  },
  paddingHorizontal: {
    paddingHorizontal: 20,
  },
  alignSelfEnd: {
    alignSelf: 'flex-end',
  },
  zIndexNegative: {
    zIndex: -1,
  },
});

export default TrialByTrialProgress;
