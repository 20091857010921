import {
  addColumns,
  createTable,
  schemaMigrations,
  unsafeExecuteSql,
} from '@nozbe/watermelondb/Schema/migrations';
import {timestamps} from 'src/providers/watermelondb/model/helpers';

export default schemaMigrations({
  migrations: [
    {
      toVersion: 2,
      steps: [
        addColumns({
          table: 'tags',
          columns: [{name: 'enabled', type: 'boolean'}],
        }),
        addColumns({
          table: 'prompts',
          columns: [{name: 'enabled', type: 'boolean'}],
        }),
      ],
    },
    {
      toVersion: 3,
      steps: [
        createTable({
          name: 'authorizations',
          columns: [
            {name: 'patient_id', type: 'string', isIndexed: true},

            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'start_date', type: 'number'},
            {name: 'end_date', type: 'number'},
            {name: 'assessment', type: 'number'},
            {name: 'supporting_assessment', type: 'number'},
            {name: 'assessment_multi_tech', type: 'number'},
            {name: 'direct_therapy', type: 'number'},
            {name: 'direct_group_therapy', type: 'number'},
            {name: 'supervision', type: 'number'},
            {name: 'supervision_multi_tech', type: 'number'},
            {name: 'caregiver_guidance', type: 'number'},
            {name: 'caregiver_guidance_group', type: 'number'},
            ...timestamps,
          ],
        }),
      ],
    },
    {
      toVersion: 4,
      steps: [
        createTable({
          name: 'authorizations',
          columns: [
            {name: 'patient_id', type: 'string', isIndexed: true},

            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'type', type: 'string'},
            {name: 'date', type: 'number'},
            {name: 'title', type: 'string'},
            {name: 'description', type: 'string'},
            ...timestamps,
          ],
        }),
      ],
    },
    {
      toVersion: 5,
      steps: [
        addColumns({
          table: 'patients',
          columns: [{name: 'diagnoses', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 6,
      steps: [
        addColumns({
          table: 'participants',
          columns: [{name: 'billable', type: 'boolean'}],
        }),
      ],
    },
    {
      toVersion: 7,
      steps: [
        createTable({
          name: 'documents',
          columns: [
            {name: 'patient_id', type: 'string', isIndexed: true},

            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'document_type', type: 'string'},
            {name: 'intake_type', type: 'string'},
            {name: 'file_name', type: 'string'},
            {name: 'file_location', type: 'string'},
            {name: 'local_file_location', type: 'string'},
            {name: 'sync_status', type: 'string'},
            ...timestamps,
          ],
        }),
      ],
    },
    {
      toVersion: 8,
      steps: [
        addColumns({
          table: 'documents',
          columns: [{name: 'starred', type: 'boolean'}],
        }),
      ],
    },
    {
      toVersion: 9,
      steps: [
        createTable({
          name: 'roles',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'name', type: 'string'},
            {name: 'managed', type: 'boolean'},
            {name: 'organization_create', type: 'boolean'},
            {name: 'organization_edit', type: 'boolean'},
            {name: 'organization_delete', type: 'boolean'},
            {name: 'organization_view', type: 'boolean'},
            {name: 'organization_assigned_only', type: 'boolean'},
            {name: 'prompt_create', type: 'boolean'},
            {name: 'prompt_edit', type: 'boolean'},
            {name: 'prompt_delete', type: 'boolean'},
            {name: 'prompt_view', type: 'boolean'},
            {name: 'prompt_assigned_only', type: 'boolean'},
            {name: 'tag_create', type: 'boolean'},
            {name: 'tag_edit', type: 'boolean'},
            {name: 'tag_delete', type: 'boolean'},
            {name: 'tag_view', type: 'boolean'},
            {name: 'tag_assigned_only', type: 'boolean'},
            {name: 'role_create', type: 'boolean'},
            {name: 'role_edit', type: 'boolean'},
            {name: 'role_delete', type: 'boolean'},
            {name: 'role_view', type: 'boolean'},
            {name: 'role_assigned_only', type: 'boolean'},
            {name: 'appointment_create', type: 'boolean'},
            {name: 'appointment_edit', type: 'boolean'},
            {name: 'appointment_delete', type: 'boolean'},
            {name: 'appointment_view', type: 'boolean'},
            {name: 'appointment_assigned_only', type: 'boolean'},
            {name: 'patient_create', type: 'boolean'},
            {name: 'patient_edit', type: 'boolean'},
            {name: 'patient_delete', type: 'boolean'},
            {name: 'patient_view', type: 'boolean'},
            {name: 'patient_assigned_only', type: 'boolean'},
            {name: 'treatment_plan_create', type: 'boolean'},
            {name: 'treatment_plan_edit', type: 'boolean'},
            {name: 'treatment_plan_delete', type: 'boolean'},
            {name: 'treatment_plan_view', type: 'boolean'},
            {name: 'treatment_plan_assigned_only', type: 'boolean'},
            {name: 'authorization_create', type: 'boolean'},
            {name: 'authorization_edit', type: 'boolean'},
            {name: 'authorization_delete', type: 'boolean'},
            {name: 'authorization_view', type: 'boolean'},
            {name: 'authorization_assigned_only', type: 'boolean'},
            {name: 'environmental_factor_create', type: 'boolean'},
            {name: 'environmental_factor_edit', type: 'boolean'},
            {name: 'environmental_factor_delete', type: 'boolean'},
            {name: 'environmental_factor_view', type: 'boolean'},
            {name: 'environmental_factor_assigned_only', type: 'boolean'},
            {name: 'client_document_create', type: 'boolean'},
            {name: 'client_document_edit', type: 'boolean'},
            {name: 'client_document_delete', type: 'boolean'},
            {name: 'client_document_view', type: 'boolean'},
            {name: 'client_document_assigned_only', type: 'boolean'},
            {name: 'user_create', type: 'boolean'},
            {name: 'user_edit', type: 'boolean'},
            {name: 'user_delete', type: 'boolean'},
            {name: 'user_view', type: 'boolean'},
            {name: 'user_assigned_only', type: 'boolean'},
            {name: 'assigned_client_create', type: 'boolean'},
            {name: 'assigned_client_edit', type: 'boolean'},
            {name: 'assigned_client_delete', type: 'boolean'},
            {name: 'assigned_client_view', type: 'boolean'},
            {name: 'assigned_client_assigned_only', type: 'boolean'},
            {name: 'report_create', type: 'boolean'},
            {name: 'report_edit', type: 'boolean'},
            {name: 'report_delete', type: 'boolean'},
            {name: 'report_view', type: 'boolean'},
            {name: 'report_assigned_only', type: 'boolean'},
            {name: 'program_create', type: 'boolean'},
            {name: 'program_edit', type: 'boolean'},
            {name: 'program_delete', type: 'boolean'},
            {name: 'program_view', type: 'boolean'},
            {name: 'program_assigned_only', type: 'boolean'},
            ...timestamps,
          ],
        }),
        addColumns({
          table: 'users',
          columns: [{name: 'role', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 10,
      steps: [
        addColumns({
          table: 'instances',
          columns: [{name: 'logo', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 11,
      steps: [
        addColumns({
          table: 'appointments',
          columns: [{name: 'rrule', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 12,
      steps: [
        addColumns({
          table: 'sessions',
          columns: [{name: 'date', type: 'number'}],
        }),
      ],
    },
    {
      toVersion: 13,
      steps: [
        addColumns({
          table: 'programs',
          columns: [{name: 'intensity', type: 'boolean'}],
        }),
        addColumns({
          table: 'sets',
          columns: [{name: 'intensity', type: 'string'}],
        }),
        addColumns({
          table: 'events',
          columns: [{name: 'intensity', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 14,
      steps: [
        addColumns({
          table: 'roles',
          columns: [
            {name: 'timesheet_create', type: 'boolean'},
            {name: 'timesheet_edit', type: 'boolean'},
            {name: 'timesheet_delete', type: 'boolean'},
            {name: 'timesheet_view', type: 'boolean'},
            {name: 'timesheet_assigned_only', type: 'boolean'},
          ],
        }),
      ],
    },
    {
      toVersion: 15,
      steps: [
        addColumns({
          table: 'programs',
          columns: [{name: 'interval_occurs', type: 'number'}],
        }),
      ],
    },
    {
      toVersion: 16,
      steps: [
        addColumns({
          table: 'instances',
          columns: [{name: 'note_template', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 17,
      steps: [
        addColumns({
          table: 'programs',
          columns: [{name: 'interval_recurrences', type: 'number'}],
        }),
      ],
    },
    {
      toVersion: 18,
      steps: [
        addColumns({
          table: 'programs',
          columns: [{name: 'interval_automation', type: 'boolean'}],
        }),
      ],
    },
    {
      toVersion: 19,
      steps: [
        addColumns({
          table: 'users',
          columns: [
            {name: 'credential', type: 'string'},
            {name: 'license_number', type: 'string'},
          ],
        }),
      ],
    },
    {
      toVersion: 20,
      steps: [
        addColumns({
          table: 'participants',
          columns: [{name: 'supervision', type: 'boolean'}],
        }),
      ],
    },
    {
      toVersion: 21,
      steps: [
        addColumns({
          table: 'sessions',
          columns: [
            {name: 'supervision_start_timestamp', type: 'number'},
            {name: 'supervision_end_timestamp', type: 'number'},
            {name: 'edited_supervision_start_timestamp', type: 'number'},
            {name: 'edited_supervision_end_timestamp', type: 'number'},
            {name: 'supervision_note', type: 'string'},
            {name: 'supervision_signature', type: 'string'},
          ],
        }),
      ],
    },
    {
      toVersion: 22,
      steps: [
        addColumns({
          table: 'sessions',
          columns: [{name: 'supervision_submission_timestamp', type: 'number'}],
        }),
      ],
    },
    {
      toVersion: 23,
      steps: [
        createTable({
          name: 'session_programs',
          columns: [
            {name: 'program_id', type: 'string', isIndexed: true},
            {name: 'session_id', type: 'string', isIndexed: true},

            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'rank', type: 'string'},
            {name: 'enabled', type: 'boolean'},
            ...timestamps,
          ],
        }),
        createTable({
          name: 'session_targets',
          columns: [
            {name: 'session_program_id', type: 'string', isIndexed: true},
            {name: 'target_id', type: 'string', isIndexed: true},

            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'rank', type: 'string'},
            {name: 'enabled', type: 'boolean'},
            ...timestamps,
          ],
        }),
      ],
    },
    {
      toVersion: 24,
      steps: [
        addColumns({
          table: 'sets',
          columns: [{name: 'number_of_trials', type: 'number'}],
        }),
      ],
    },
    {
      toVersion: 25,
      steps: [
        createTable({
          name: 'note_templates',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'cpt', type: 'string'},
            {name: 'title', type: 'string'},
            {name: 'template', type: 'string'},
            ...timestamps,
          ],
        }),
      ],
    },
    {
      toVersion: 26,
      steps: [
        addColumns({
          table: 'roles',
          columns: [
            {name: 'note_template_create', type: 'boolean'},
            {name: 'note_template_edit', type: 'boolean'},
            {name: 'note_template_delete', type: 'boolean'},
            {name: 'note_template_view', type: 'boolean'},
            {name: 'note_template_assigned_only', type: 'boolean'},
          ],
        }),
      ],
    },
    {
      toVersion: 27,
      steps: [
        addColumns({
          table: 'note_templates',
          columns: [{name: 'managed', type: 'boolean'}],
        }),
      ],
    },
    {
      toVersion: 28,
      steps: [
        addColumns({
          table: 'participants',
          columns: [
            {name: 'start_time', type: 'number'},
            {name: 'end_time', type: 'number'},
          ],
        }),
      ],
    },
    {
      toVersion: 29,
      steps: [
        addColumns({
          table: 'users',
          columns: [{name: 'modifier', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 30,
      steps: [
        addColumns({
          table: 'roles',
          columns: [
            {name: 'staff_document_create', type: 'boolean'},
            {name: 'staff_document_edit', type: 'boolean'},
            {name: 'staff_document_delete', type: 'boolean'},
            {name: 'staff_document_view', type: 'boolean'},
            {name: 'staff_document_assigned_only', type: 'boolean'},
          ],
        }),
        addColumns({
          table: 'documents',
          columns: [{name: 'user_id', type: 'string', isIndexed: true}],
        }),
      ],
    },
    {
      toVersion: 31,
      steps: [
        createTable({
          name: 'notifications',
          columns: [
            {name: '_partition', type: 'string'},
            {name: 'user_id', type: 'string'},
            {name: 'subject_model', type: 'string'},
            {name: 'subject_id', type: 'string'},
            {name: 'title', type: 'string'},
            {name: 'body', type: 'string'},
            {name: 'read_at', type: 'number'},
            ...timestamps,
          ],
        }),
      ],
    },
    {
      toVersion: 32,
      steps: [
        addColumns({
          table: 'appointments',
          columns: [
            {name: 'cancellation_reason', type: 'string'},
            {name: 'cancellation_more_info', type: 'string'},
          ],
        }),
      ],
    },
    {
      toVersion: 33,
      steps: [
        addColumns({
          table: 'instances',
          columns: [{name: 'address', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 34,
      steps: [
        addColumns({
          table: 'instances',
          columns: [
            {name: 'tin', type: 'string'},
            {name: 'npi', type: 'string'},
          ],
        }),
      ],
    },
    {
      toVersion: 35,
      steps: [
        addColumns({
          table: 'instances',
          columns: [{name: 'billing_address', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 36,
      steps: [
        addColumns({
          table: 'programs',
          columns: [{name: 'state', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 37,
      steps: [
        addColumns({
          table: 'sets',
          columns: [
            {name: 'not_addressed_reason', type: 'string'},
            {name: 'not_addressed_description', type: 'string'},
          ],
        }),
        addColumns({
          table: 'sessions',
          columns: [
            {name: 'post_session_emotions', type: 'string'},
            {name: 'post_session_impressions', type: 'string'},
          ],
        }),
      ],
    },
    {
      toVersion: 38,
      steps: [
        addColumns({
          table: 'patients',
          columns: [
            {name: 'middle_name', type: 'string'},
            {name: 'ssn', type: 'string'},
          ],
        }),
        createTable({
          name: 'caregivers',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'patient_id', type: 'string', isIndexed: true},
            {name: 'first_name', type: 'string'},
            {name: 'last_name', type: 'string'},
            {name: 'relationship', type: 'string'},
            {name: 'address', type: 'string'},
            {name: 'work_address', type: 'string'},
            {name: 'email', type: 'string'},
            {name: 'mobile_phone', type: 'string'},
            ...timestamps,
          ],
        }),
      ],
    },
    {
      toVersion: 39,
      steps: [
        addColumns({
          table: 'instances',
          columns: [{name: 'account_number', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 40,
      steps: [
        addColumns({
          table: 'sessions',
          columns: [{name: 'session_date', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 41,
      steps: [
        createTable({
          name: 'medications',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'patient_id', type: 'string', isIndexed: true},
            {name: 'medication', type: 'string'},
            {name: 'dosage', type: 'string'},
            {name: 'frequency', type: 'string'},
            {name: 'archived', type: 'boolean'},
            ...timestamps,
          ],
        }),
        createTable({
          name: 'diagnoses',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'patient_id', type: 'string', isIndexed: true},
            {name: 'diagnosis', type: 'string'},
            {name: 'severity', type: 'string'},
            {name: 'diagnosis_date', type: 'number'},
            {name: 'archived', type: 'boolean'},
            ...timestamps,
          ],
        }),
      ],
    },
    {
      toVersion: 42,
      steps: [
        addColumns({
          table: 'authorizations',
          columns: [
            {name: 'authorization_number', type: 'string'},
            {name: 'cpt97151', type: 'number'},
            {name: 'cpt97152', type: 'number'},
            {name: 'cpt97153', type: 'number'},
            {name: 'cpt97154', type: 'number'},
            {name: 'cpt97155', type: 'number'},
            {name: 'cpt97156', type: 'number'},
            {name: 'cpt97157', type: 'number'},
            {name: 'cpt0373T', type: 'number'},
            {name: 'cpt0362T', type: 'number'},
            {name: 'cptT1024', type: 'number'},
            {name: 'cpt92507', type: 'number'},
            {name: 'cpt92508', type: 'number'},
            {name: 'cpt92526', type: 'number'},
            {name: 'cpt92609', type: 'number'},
            {name: 'cpt92522', type: 'number'},
            {name: 'cpt92523', type: 'number'},
            {name: 'cpt92610', type: 'number'},
          ],
        }),
      ],
    },
    {
      toVersion: 43,
      steps: [
        addColumns({
          table: 'roles',
          columns: [
            {name: 'assigned_staff_create', type: 'boolean'},
            {name: 'assigned_staff_edit', type: 'boolean'},
            {name: 'assigned_staff_delete', type: 'boolean'},
            {name: 'assigned_staff_view', type: 'boolean'},
            {name: 'assigned_staff_assigned_only', type: 'boolean'},
          ],
        }),
      ],
    },
    {
      toVersion: 44,
      steps: [
        addColumns({
          table: 'roles',
          columns: [
            {name: 'client_history_create', type: 'boolean'},
            {name: 'client_history_edit', type: 'boolean'},
            {name: 'client_history_delete', type: 'boolean'},
            {name: 'client_history_view', type: 'boolean'},
            {name: 'client_history_assigned_only', type: 'boolean'},
            {name: 'staff_history_create', type: 'boolean'},
            {name: 'staff_history_edit', type: 'boolean'},
            {name: 'staff_history_delete', type: 'boolean'},
            {name: 'staff_history_view', type: 'boolean'},
            {name: 'staff_history_assigned_only', type: 'boolean'},
            {name: 'session_create', type: 'boolean'},
            {name: 'session_edit', type: 'boolean'},
            {name: 'session_delete', type: 'boolean'},
            {name: 'session_view', type: 'boolean'},
            {name: 'session_assigned_only', type: 'boolean'},
          ],
        }),
      ],
    },
    {
      toVersion: 45,
      steps: [
        addColumns({
          table: 'targets',
          columns: [{name: 'enabled', type: 'boolean'}],
        }),
      ],
    },
    {
      toVersion: 46,
      steps: [
        addColumns({
          table: 'sessions',
          columns: [
            {name: 'note_edited_by', type: 'string'},
            {name: 'note_edited_timestamp', type: 'number'},
          ],
        }),
      ],
    },
    {
      toVersion: 47,
      steps: [
        addColumns({
          table: 'instances',
          columns: [{name: 'reporting', type: 'boolean'}],
        }),
      ],
    },
    {
      toVersion: 48,
      steps: [
        addColumns({
          table: 'patients',
          columns: [{name: 'state', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 49,
      steps: [
        addColumns({
          table: 'users',
          columns: [{name: 'state', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 50,
      steps: [
        addColumns({
          table: 'programs',
          columns: [{name: 'baseline_sessions', type: 'number'}],
        }),
      ],
    },
    {
      toVersion: 51,
      steps: [
        addColumns({
          table: 'note_templates',
          columns: [
            {name: 'organization_information', type: 'boolean'},
            {name: 'organization_name', type: 'boolean'},
            {name: 'organization_logo', type: 'boolean'},
            {name: 'organization_address', type: 'boolean'},
            {name: 'organization_billing_address', type: 'boolean'},
            {name: 'organization_tin', type: 'boolean'},
            {name: 'organization_npi', type: 'boolean'},
            // {name: 'organization_note_template', type: 'boolean'},
            {name: 'organization_account_number', type: 'boolean'},

            {name: 'client_information', type: 'boolean'},
            {name: 'client_first_name', type: 'boolean'},
            {name: 'client_middle_name', type: 'boolean'},
            {name: 'client_last_name', type: 'boolean'},
            {name: 'client_gender', type: 'boolean'},
            {name: 'client_birth_date', type: 'boolean'},
            {name: 'client_ssn', type: 'boolean'},
            {name: 'client_diagnoses', type: 'boolean'},
            {name: 'client_assigned_staff', type: 'boolean'},

            {name: 'session_information', type: 'boolean'},
            {name: 'session_staff', type: 'boolean'},
            {name: 'session_date_of_service', type: 'boolean'},
            {name: 'session_start_time', type: 'boolean'},
            {name: 'session_end_time', type: 'boolean'},
            {name: 'session_duration', type: 'boolean'},
            {name: 'session_hour_format', type: 'boolean'},
            {name: 'session_type', type: 'boolean'},
            {name: 'session_location', type: 'boolean'},

            {name: 'clinical_note', type: 'boolean'},

            {name: 'session_data', type: 'boolean'},
            {name: 'skill', type: 'boolean'},
            {name: 'behavior', type: 'boolean'},

            {name: 'additional_fields', type: 'boolean'},

            {name: 'signature', type: 'boolean'},

            {name: 'published', type: 'boolean'},
          ],
        }),
        addColumns({
          table: 'sessions',
          columns: [{name: 'note_template_id', type: 'string'}],
        }),
        addColumns({
          table: 'appointments',
          columns: [{name: 'note_template_id', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 52,
      steps: [
        addColumns({
          table: 'sessions',
          columns: [{name: 'supervision_note_template_id', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 53,
      steps: [
        createTable({
          name: 'instance_diagnoses',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'instance_id', type: 'string', isIndexed: true},
            {name: 'diagnosis', type: 'string'},
            {name: 'name', type: 'string'},
            ...timestamps,
          ],
        }),
        addColumns({
          table: 'diagnoses',
          columns: [
            {name: 'instance_diagnosis_id', type: 'string', isIndexed: true},
          ],
        }),
        addColumns({
          table: 'roles',
          columns: [
            {name: 'organization_diagnosis_create', type: 'boolean'},
            {name: 'organization_diagnosis_edit', type: 'boolean'},
            {name: 'organization_diagnosis_delete', type: 'boolean'},
            {name: 'organization_diagnosis_view', type: 'boolean'},
            {name: 'organization_diagnosis_assigned_only', type: 'boolean'},
          ],
        }),
      ],
    },
    {
      toVersion: 54,
      steps: [
        addColumns({
          table: 'appointments',
          columns: [
            {name: 'start_timestamp', type: 'number'},
            {name: 'end_timestamp', type: 'number'},
          ],
        }),
      ],
    },
    {
      toVersion: 55,
      steps: [
        createTable({
          name: 'note_template_versions',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'note_template_id', type: 'string'},

            {name: 'managed', type: 'boolean'},
            {name: 'title', type: 'string'},
            {name: 'template', type: 'string'},
            {name: 'version', type: 'number'},

            {name: 'organization_information', type: 'boolean'},
            {name: 'organization_name', type: 'boolean'},
            {name: 'organization_logo', type: 'boolean'},
            {name: 'organization_address', type: 'boolean'},
            {name: 'organization_billing_address', type: 'boolean'},
            {name: 'organization_tin', type: 'boolean'},
            {name: 'organization_npi', type: 'boolean'},
            {name: 'organization_account_number', type: 'boolean'},

            {name: 'client_information', type: 'boolean'},
            {name: 'client_first_name', type: 'boolean'},
            {name: 'client_middle_name', type: 'boolean'},
            {name: 'client_last_name', type: 'boolean'},
            {name: 'client_gender', type: 'boolean'},
            {name: 'client_birth_date', type: 'boolean'},
            {name: 'client_ssn', type: 'boolean'},
            {name: 'client_diagnoses', type: 'boolean'},
            {name: 'client_assigned_staff', type: 'boolean'},

            {name: 'session_information', type: 'boolean'},
            {name: 'session_staff', type: 'boolean'},
            {name: 'session_date_of_service', type: 'boolean'},
            {name: 'session_start_time', type: 'boolean'},
            {name: 'session_end_time', type: 'boolean'},
            {name: 'session_duration', type: 'boolean'},
            {name: 'session_hour_format', type: 'boolean'},
            {name: 'session_type', type: 'boolean'},
            {name: 'session_location', type: 'boolean'},
            {name: 'clinical_note', type: 'boolean'},
            {name: 'session_data', type: 'boolean'},

            {name: 'skill', type: 'boolean'},
            {name: 'behavior', type: 'boolean'},
            {name: 'additional_fields', type: 'boolean'},
            {name: 'form_builder_values', type: 'string'},
            {name: 'signature', type: 'boolean'},
            {name: 'published_at', type: 'number'},
            ...timestamps,
          ],
        }),
        addColumns({
          table: 'sessions',
          columns: [
            {name: 'additional_fields', type: 'string'},
            {name: 'note_template_version_id', type: 'string'},
            {name: 'supervision_additional_fields', type: 'string'},
            {name: 'supervision_note_template_version_id', type: 'string'},
          ],
        }),
      ],
    },
    {
      toVersion: 56,
      steps: [
        createTable({
          name: 'signatures',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'entity_id', type: 'string', isIndexed: true},
            {name: 'entity', type: 'string'},
            {name: 'signature', type: 'string'},
            {name: 'type', type: 'string'},
            {name: 'signatory', type: 'string'},
            ...timestamps,
          ],
        }),
      ],
    },
    {
      toVersion: 57,
      steps: [
        createTable({
          name: 'notes',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'entity_id', type: 'string'},
            {name: 'entity', type: 'string'},
            {name: 'description', type: 'string'},
            ...timestamps,
          ],
        }),
      ],
    },
    {
      toVersion: 58,
      steps: [
        addColumns({
          table: 'note_template_versions',
          columns: [{name: 'client_age_in_months', type: 'boolean'}],
        }),
      ],
    },
    {
      toVersion: 59,
      steps: [
        addColumns({
          table: 'instances',
          columns: [{name: 'actual_time', type: 'boolean'}],
        }),
      ],
    },
    {
      toVersion: 60,
      steps: [
        addColumns({
          table: 'users',
          columns: [{name: 'credentials', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 61,
      steps: [
        addColumns({
          table: 'appointments',
          columns: [
            {name: 'address', type: 'string'},
            {name: 'custom_address', type: 'string'},
          ],
        }),
        addColumns({
          table: 'participants',
          columns: [{name: 'cpt', type: 'string'}],
        }),
        addColumns({
          table: 'sessions',
          columns: [
            {name: 'type', type: 'string'},
            {name: 'claim_id', type: 'string'},
            {name: 'cpt', type: 'string'},
            {name: 'location', type: 'string'},
            {name: 'address', type: 'string'},
            {name: 'custom_address', type: 'string'},
          ],
        }),
        addColumns({
          table: 'instances',
          columns: [
            {name: 'dba', type: 'string'},
            {name: 'billing', type: 'boolean'},
            {name: 'taxonomy', type: 'string'},
            {name: 'single_address', type: 'boolean'},
          ],
        }),
        addColumns({
          table: 'note_template_versions',
          columns: [{name: 'session_address', type: 'boolean'}],
        }),
        addColumns({
          table: 'roles',
          columns: [
            {name: 'organization_location_create', type: 'boolean'},
            {name: 'organization_location_edit', type: 'boolean'},
            {name: 'organization_location_delete', type: 'boolean'},
            {name: 'organization_location_view', type: 'boolean'},
            {name: 'organization_location_assigned_only', type: 'boolean'},
            {name: 'billing_create', type: 'boolean'},
            {name: 'billing_edit', type: 'boolean'},
            {name: 'billing_delete', type: 'boolean'},
            {name: 'billing_view', type: 'boolean'},
            {name: 'billing_assigned_only', type: 'boolean'},
            {name: 'billing_payer_create', type: 'boolean'},
            {name: 'billing_payer_edit', type: 'boolean'},
            {name: 'billing_payer_delete', type: 'boolean'},
            {name: 'billing_payer_view', type: 'boolean'},
            {name: 'billing_payer_assigned_only', type: 'boolean'},
            {name: 'billing_claim_create', type: 'boolean'},
            {name: 'billing_claim_edit', type: 'boolean'},
            {name: 'billing_claim_delete', type: 'boolean'},
            {name: 'billing_claim_view', type: 'boolean'},
            {name: 'billing_claim_assigned_only', type: 'boolean'},
          ],
        }),
        createTable({
          name: 'credentials',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'user_id', type: 'string'},
            {name: 'npi', type: 'string'},
            {name: 'tin', type: 'string'},
            {name: 'ssn', type: 'string'},
            {name: 'taxonomy', type: 'string'},
            {name: 'first_name', type: 'string'},
            {name: 'middle_name', type: 'string'},
            {name: 'last_name', type: 'string'},
            {name: 'phone_number', type: 'string'},
            {name: 'email', type: 'string'},
            {name: 'address', type: 'string'},
            ...timestamps,
          ],
        }),
        createTable({
          name: 'claims',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'patient_id', type: 'string'},
            {name: 'status', type: 'string'},
            ...timestamps,
          ],
        }),
        createTable({
          name: 'service_lines',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'claim_id', type: 'string'},
            {name: 'session_id', type: 'string'},
            {name: 'user_id', type: 'string'},
            {name: 'start_date', type: 'number'},
            {name: 'end_date', type: 'number'},
            {name: 'cpt', type: 'string'},
            {name: 'description', type: 'string'},
            {name: 'modifier_1', type: 'string'},
            {name: 'modifier_2', type: 'string'},
            {name: 'modifier_3', type: 'string'},
            {name: 'modifier_4', type: 'string'},
            {name: 'units', type: 'string'},
            {name: 'amount', type: 'string'},
            ...timestamps,
          ],
        }),
        createTable({
          name: 'locations',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'instance_id', type: 'string'},
            {name: 'address', type: 'string'},
            {name: 'name', type: 'string'},
            ...timestamps,
          ],
        }),
        createTable({
          name: 'payers',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'name', type: 'string'},
            {name: 'payer_id', type: 'string'},
            {name: 'inovalon_id', type: 'number'},
            {name: 'staff_ids', type: 'boolean'},
            {name: 'enrollment', type: 'string'},
            {name: 'address', type: 'string'},
            {name: 'mobile_phone', type: 'string'},
            ...timestamps,
          ],
        }),
        createTable({
          name: 'payer_credential',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'credential_id', type: 'string', isIndexed: true},
            {name: 'payer_id', type: 'string', isIndexed: true},
            {name: 'legacy_id', type: 'string'},
            ...timestamps,
          ],
        }),
        createTable({
          name: 'payer_plans',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'payer_id', type: 'string', isIndexed: true},
            {name: 'name', type: 'string'},
            {name: 'fee_schedule', type: 'string'},
            ...timestamps,
          ],
        }),
        createTable({
          name: 'insurances',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'patient_id', type: 'string'},
            {name: 'payer_id', type: 'string'},
            {name: 'plan_id', type: 'string'},
            {name: 'member_id', type: 'string'},
            {name: 'group_number', type: 'string'},
            {name: 'start_date', type: 'number'},
            {name: 'end_date', type: 'number'},
            {name: 'rank', type: 'string'},
            {name: 'subscriber', type: 'string'},
            ...timestamps,
          ],
        }),
        addColumns({
          table: 'patients',
          columns: [
            {name: 'address', type: 'string'},
            {name: 'staff_id', type: 'string'},
            {name: 'intake_date', type: 'number'},
            {name: 'primary_id', type: 'string'},
            {name: 'referring_id', type: 'string'},
            {name: 'kafka', type: 'number'},
            {name: 'same_as_primary', type: 'boolean'},
          ],
        }),
        addColumns({
          table: 'diagnoses',
          columns: [{name: 'rank', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 62,
      steps: [
        addColumns({
          table: 'payers',
          columns: [{name: 'legacy_id', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 63,
      steps: [
        addColumns({
          table: 'authorizations',
          columns: [{name: 'payer_id', type: 'string'}],
        }),
        addColumns({
          table: 'sessions',
          columns: [{name: 'supervising_id', type: 'string'}],
        }),
        addColumns({
          table: 'service_lines',
          columns: [{name: 'diagnosis_pointer', type: 'string'}],
        }),
        addColumns({
          table: 'claims',
          columns: [
            {name: 'box_8', type: 'string'},
            {name: 'box_9b', type: 'string'},
            {name: 'box_9c', type: 'string'},
            {name: 'box_10d', type: 'string'},
            {name: 'box_19', type: 'string'},
            {name: 'box_30', type: 'string'},
          ],
        }),
        createTable({
          name: 'claim_events',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'claim_id', type: 'string'},
            {name: 'inovalon_id', type: 'string'},
            {name: 'timestamp', type: 'number'},
            {name: 'type', type: 'string'},
            {name: 'description', type: 'string'},
            {name: 'user', type: 'string'},
            {name: 'details', type: 'string'},
            ...timestamps,
          ],
        }),
      ],
    },
    {
      toVersion: 64,
      steps: [
        addColumns({
          table: 'insurances',
          columns: [{name: 'authorization_id', type: 'string'}],
        }),
        addColumns({
          table: 'payers',
          columns: [{name: 'taxonomy', type: 'string'}],
        }),
        addColumns({
          table: 'payer_credential',
          columns: [{name: 'taxonomy', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 65,
      steps: [
        addColumns({
          table: 'authorizations',
          columns: [
            {name: 'cptH2019', type: 'number'},
            {name: 'cptT2024', type: 'number'},
          ],
        }),
      ],
    },
    {
      toVersion: 66,
      steps: [
        addColumns({
          table: 'patients',
          columns: [{name: 'rendering_id', type: 'number'}],
        }),
      ],
    },
    {
      toVersion: 67,
      steps: [
        addColumns({
          table: 'claims',
          columns: [
            {name: 'kafka', type: 'number'},
            {name: 'payload', type: 'string'},
          ],
        }),
      ],
    },
    {
      toVersion: 68,
      steps: [
        createTable({
          name: 'taggables',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'entity_id', type: 'string'},
            {name: 'entity', type: 'string'},
            {name: 'tag_id', type: 'string'},
            ...timestamps,
          ],
        }),
      ],
    },
    {
      toVersion: 69,
      steps: [
        addColumns({
          table: 'note_template_versions',
          columns: [{name: 'show_authors_and_timestamps', type: 'boolean'}],
        }),
        addColumns({
          table: 'sessions',
          columns: [{name: 'show_authors_and_timestamps', type: 'boolean'}],
        }),
      ],
    },
    {
      toVersion: 70,
      steps: [
        addColumns({
          table: 'roles',
          columns: [
            {name: 'audit_create', type: 'boolean'},
            {name: 'audit_edit', type: 'boolean'},
            {name: 'audit_delete', type: 'boolean'},
            {name: 'audit_view', type: 'boolean'},
            {name: 'audit_assigned_only', type: 'boolean'},
            {name: 'audit_session_create', type: 'boolean'},
            {name: 'audit_session_edit', type: 'boolean'},
            {name: 'audit_session_delete', type: 'boolean'},
            {name: 'audit_session_view', type: 'boolean'},
            {name: 'audit_session_assigned_only', type: 'boolean'},
          ],
        }),
      ],
    },
    {
      toVersion: 71,
      steps: [
        createTable({
          name: 'remits',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'payer_id', type: 'string', isIndexed: true},
            {name: 'inovalon_id', type: 'string'},
            {name: 'response', type: 'string'},
            ...timestamps,
          ],
        }),
        createTable({
          name: 'remit_claim',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'claim_id', type: 'string', isIndexed: true},
            {name: 'remit_id', type: 'string', isIndexed: true},
            {name: 'inovalon_id', type: 'string'},
            {name: 'response', type: 'string'},
            ...timestamps,
          ],
        }),
        createTable({
          name: 'transactions',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'remit_claim_id', type: 'string', isIndexed: true},
            {name: 'service_line_id', type: 'string', isIndexed: true},
            {name: 'response', type: 'string'},
            ...timestamps,
          ],
        }),
        addColumns({
          table: 'authorizations',
          columns: [{name: 'cptH0032', type: 'number'}],
        }),
      ],
    },
    {
      toVersion: 72,
      steps: [
        addColumns({
          table: 'note_template_versions',
          columns: [
            {name: 'primary_member_id', type: 'boolean'},
            {name: 'secondary_member_id', type: 'boolean'},
            {name: 'staff_npi', type: 'boolean'},
            {name: 'staff_license_number', type: 'boolean'},
            {name: 'session_units_of_service', type: 'boolean'},
          ],
        }),
      ],
    },
    {
      toVersion: 73,
      steps: [
        unsafeExecuteSql('DROP TABLE IF EXISTS remits;'),
        createTable({
          name: 'remits',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'payer_id', type: 'string', isIndexed: true},
            {name: 'inovalon_id', type: 'string'},
            {name: 'response', type: 'string'},
            ...timestamps,
          ],
        }),
        unsafeExecuteSql('DROP TABLE IF EXISTS remit_claim;'),
        createTable({
          name: 'remit_claim',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'claim_id', type: 'string', isIndexed: true},
            {name: 'remit_id', type: 'string', isIndexed: true},
            {name: 'inovalon_id', type: 'string'},
            {name: 'response', type: 'string'},
            ...timestamps,
          ],
        }),
        unsafeExecuteSql('DROP TABLE IF EXISTS transactions;'),
        createTable({
          name: 'transactions',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'remit_claim_id', type: 'string', isIndexed: true},
            {name: 'service_line_id', type: 'string', isIndexed: true},
            {name: 'response', type: 'string'},
            ...timestamps,
          ],
        }),
      ],
    },
    {
      toVersion: 74,
      steps: [
        addColumns({
          table: 'authorizations',
          columns: [
            {name: 'cpt90791', type: 'number'},
            {name: 'cpt90837', type: 'number'},
          ],
        }),
      ],
    },
    {
      toVersion: 75,
      steps: [
        addColumns({
          table: 'instances',
          columns: [{name: 'use_client_identifier', type: 'boolean'}],
        }),
      ],
    },
    {
      toVersion: 76,
      steps: [
        addColumns({
          table: 'patients',
          columns: [{name: 'school_id', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 77,
      steps: [
        addColumns({
          table: 'authorizations',
          columns: [
            {name: 'cptH0031', type: 'number'},
            {name: 'cptH2012', type: 'number'},
            {name: 'cptS5111', type: 'number'},
          ],
        }),
      ],
    },
    {
      toVersion: 78,
      steps: [
        addColumns({
          table: 'programs',
          columns: [
            {name: 'maintenance_cadence_weekly', type: 'string'},
            {name: 'maintenance_cadence_monthly', type: 'number'},
            {name: 'maintenance_allowance', type: 'number'},
            {name: 'adjustment', type: 'string'},
          ],
        }),
      ],
    },
    {
      toVersion: 79,
      steps: [
        addColumns({
          table: 'authorizations',
          columns: [{name: 'cptH2014', type: 'number'}],
        }),
        addColumns({
          table: 'sessions',
          columns: [{name: 'rendering_id', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 80,
      steps: [
        addColumns({
          table: 'payer_plans',
          columns: [{name: 'notes', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 81,
      steps: [
        addColumns({
          table: 'users',
          columns: [{name: 'middle_name', type: 'string'}],
        }),
        addColumns({
          table: 'authorizations',
          columns: [{name: 'cptH0046', type: 'number'}],
        }),
      ],
    },
    {
      toVersion: 82,
      steps: [
        addColumns({
          table: 'patients',
          columns: [{name: 'bill_by_id', type: 'string'}],
        }),
      ],
    },
    {
      toVersion: 83,
      steps: [
        createTable({
          name: 'assessments',
          columns: [
            {name: 'patient_id', type: 'string', isIndexed: true},
            {name: 'document_id', type: 'string', isIndexed: true},
            {name: '_partition', type: 'string'},
            {name: 'assessment_date', type: 'number'},
            {name: 'start_time', type: 'string'},
            {name: 'end_time', type: 'string'},
            {name: 'overview', type: 'string'},
            {name: 'assessor', type: 'string'},
            ...timestamps,
          ],
        }),
        createTable({
          name: 'progress_reports',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'patient_id', type: 'string', isIndexed: true},
            {name: 'authorization_id', type: 'string'},
            {name: 'type', type: 'string'},
            {name: 'start_date', type: 'number'},
            {name: 'end_date', type: 'number'},

            {name: 'caregiver_guidance_info', type: 'string'},
            {name: 'background_info', type: 'string'},
            {name: 'medical_info', type: 'string'},
            {name: 'behavior_intervention_plan', type: 'string'},
            {name: 'summary', type: 'string'},
            {name: 'crisis_plan', type: 'string'},
            {name: 'transition_plan', type: 'string'},
            {name: 'recommendations', type: 'string'},
            {name: 'therapist_signature', type: 'string'},
            {name: 'therapist_signature_2', type: 'string'},
            {name: 'parent_signature', type: 'string'},

            {name: 'caregiver_guidance_info_include', type: 'boolean'},
            {name: 'background_info_include', type: 'boolean'},
            {name: 'medical_info_include', type: 'boolean'},
            {name: 'behavior_intervention_plan_include', type: 'boolean'},
            {name: 'summary_include', type: 'boolean'},
            {name: 'crisis_plan_include', type: 'boolean'},
            {name: 'transition_plan_include', type: 'boolean'},
            {name: 'recommendations_include', type: 'boolean'},
            {name: 'signature_include', type: 'boolean'},

            {name: 'cpt97151', type: 'number'},
            {name: 'cpt97152', type: 'number'},
            {name: 'cpt97153', type: 'number'},
            {name: 'cpt97154', type: 'number'},
            {name: 'cpt97155', type: 'number'},
            {name: 'cpt97156', type: 'number'},
            {name: 'cpt97157', type: 'number'},
            {name: 'cpt0373T', type: 'number'},
            {name: 'cpt0362T', type: 'number'},
            {name: 'cptT1024', type: 'number'},
            {name: 'cpt92507', type: 'number'},
            {name: 'cpt92508', type: 'number'},
            {name: 'cpt92526', type: 'number'},
            {name: 'cpt92609', type: 'number'},
            {name: 'cpt92522', type: 'number'},
            {name: 'cpt92523', type: 'number'},
            {name: 'cpt92610', type: 'number'},
            {name: 'cptH2019', type: 'number'},
            {name: 'cptT2024', type: 'number'},
            {name: 'cptH0032', type: 'number'},
            {name: 'cpt90791', type: 'number'},
            {name: 'cpt90837', type: 'number'},
            {name: 'cptH0031', type: 'number'},
            {name: 'cptH2012', type: 'number'},
            {name: 'cptS5111', type: 'number'},
            {name: 'cptH2014', type: 'number'},
            {name: 'cptH0046', type: 'number'},

            {name: 'submitted_at', type: 'number'},
            ...timestamps,
          ],
        }),
        createTable({
          name: 'progress_report_program',
          columns: [
            {name: 'program_id', type: 'string', isIndexed: true},
            {name: 'progress_report_id', type: 'string', isIndexed: true},

            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'program_include', type: 'boolean'},
            {name: 'graph_include', type: 'boolean'},
            {name: 'start_date', type: 'number'},
            {name: 'end_date', type: 'number'},
            {name: 'program_info', type: 'string'},
            ...timestamps,
          ],
        }),
        createTable({
          name: 'progress_report_caregiver',
          columns: [
            {name: 'caregiver_id', type: 'string', isIndexed: true},
            {name: 'progress_report_id', type: 'string', isIndexed: true},

            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'caregiver_include', type: 'boolean'},
            ...timestamps,
          ],
        }),
        createTable({
          name: 'progress_report_assessment',
          columns: [
            {name: 'assessment_id', type: 'string', isIndexed: true},
            {name: 'progress_report_id', type: 'string', isIndexed: true},

            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'assessment_date_include', type: 'boolean'},
            {name: 'assessment_time_include', type: 'boolean'},
            {name: 'assessment_assessor_include', type: 'boolean'},
            {name: 'assessment_age_include', type: 'boolean'},
            ...timestamps,
          ],
        }),
        addColumns({
          table: 'documents',
          columns: [{name: 'assessment_id', type: 'string'}],
        }),
        addColumns({
          table: 'roles',
          columns: [
            {name: 'progress_report_create', type: 'boolean'},
            {name: 'progress_report_edit', type: 'boolean'},
            {name: 'progress_report_delete', type: 'boolean'},
            {name: 'progress_report_view', type: 'boolean'},
          ],
        }),
      ],
    },
    {
      toVersion: 84,
      steps: [
        createTable({
          name: 'billing_entities',
          columns: [
            {name: '_partition', type: 'string', isIndexed: true},
            {name: 'name', type: 'string'},
            {name: 'dba', type: 'string'},
            {name: 'address', type: 'string'},
            {name: 'tin', type: 'string'},
            {name: 'npi', type: 'string'},
            {name: 'taxonomy', type: 'string'},
            ...timestamps,
          ],
        }),
        addColumns({
          table: 'patients',
          columns: [
            {name: 'bill_by_physician', type: 'boolean'},
            {name: 'bill_physician_id', type: 'string'},
          ],
        }),
      ],
    },
    {
      toVersion: 85,
      steps: [
        addColumns({
          table: 'authorizations',
          columns: [
            {name: 'state', type: 'string'},
            {name: 'cpt97158', type: 'number'},
          ],
        }),
        addColumns({
          table: 'progress_reports',
          columns: [{name: 'cpt97158', type: 'number'}],
        }),
      ],
    },
  ],
});
