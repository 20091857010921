import React, {useEffect, useState} from 'react';
import {
  ActivityIndicator,
  Platform,
  ScrollView,
  Text,
  View,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {compose} from 'recompose';
import _ from 'lodash';
import moment from 'moment';

import {EnvironmentalFactor, Patient, Program} from 'src/models';
import {Typography} from 'src/styles';
import ProgramSelect from '../program-select';
import {of} from 'rxjs';
import {oneOf} from '@nozbe/watermelondb/QueryDescription';
import ProgramGraph from '../program-graph';
import {Controller, useForm, useWatch} from 'react-hook-form';
import {useStyle} from 'src/providers/style';
import {Input as DatePeriodInput} from 'src/hook-form-inputs/date-period-buttons';
import sync from 'src/providers/watermelondb/sync';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import Bugsnag from 'src/common-utils/bugsnag-set-user';

interface Props {
  patient: Patient;
  clientEnvs: EnvironmentalFactor[];
  startDate: Date;
  endDate: Date;
  filteredPrograms: Program[];
  setExportName?: any;
  client?: any;
}

const ClientReportBeta = ({
  patient,
  clientEnvs = [],
  startDate,
  endDate,
  filteredPrograms,
  setExportName,
  client,
}: Props) => {
  const styles = useStyle();
  const programById = _.keyBy(filteredPrograms, 'id');
  const [syncedClients] = useState<any>([]);
  const database = useDatabase();
  const defaultValues = {
    programId: filteredPrograms?.[0]?.id || '',
  };
  const startTime = new Date();
  const threeMonths = new Date(
    startTime.getFullYear(),
    startTime.getMonth() - 3,
    startTime.getDate() - 2,
  ).getTime();

  const [isLoading, setIsLoading] = useState(false);

  const checkSync = async (patientId: any) => {
    if (!syncedClients.includes(patientId)) {
      setIsLoading(true);
      let sessions = await database
        .get('sessions')
        .query(
          Q.and(
            Q.where('patient_id', patientId),
            Q.where('date', Q.lt(threeMonths)),
          ),
        )
        .fetch();
      if (sessions.length > 0 && filteredPrograms.length > 0) {
        const sessionIds = sessions.map(session => session.id);
        let sets = await database
          .get('sets')
          .query(Q.and(Q.where('session_id', Q.oneOf(sessionIds))))
          .fetch();
        if (sets.length === 0) {
          try {
            await sync(database, false, false, false, false, true);
          } catch (e) {
            Bugsnag.notify(`Client Reporting Sync Error: ${e} `);
          }
        }
      }

      syncedClients.push(patientId);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    reset(defaultValues);
    if (Platform.OS === 'web') {
      checkSync(patient?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);
  const {control, reset} = useForm({defaultValues});
  const selectedProgramId = useWatch({control, name: 'programId'});

  useEffect(() => {
    if (setExportName) {
      const programName = programById[selectedProgramId]?.name;
      const clientName = patient?.lastName;
      const todayDate = moment().format('MM/DD/YYYY');
      setExportName(`${clientName}_${programName}_${todayDate}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProgramId, patient]);

  return (
    <>
      {isLoading ? (
        <>
          <ActivityIndicator size={'large'} style={[styles.paddingTop]} />
        </>
      ) : (
        <ScrollView>
          {patient ? (
            <>
              <View style={[styles.padding]}>
                <Text
                  style={[
                    Typography.H1,
                  ]}>{`${patient?.firstName} ${patient?.lastName}`}</Text>
              </View>
              {/* <ReportStatistics programs={programs} /> */}
              {/* <View style={[styles.break1024]}>
            <View style={[styles.card, styles.flex_4]}>
              <View
                style={[
                  styles.row,
                  styles.justifySpaceBetween,
                  styles.alignCenter,
                  styles.height40,
                ]}>
                <Text style={styles.labelTxt}>Total Sessions</Text>
                <Text style={styles.valueTxt}>{filteredSessions.length}</Text>
              </View>
              <TotalSessionGraph
                sessions={filteredSessions}
                startDate={startDate}
                endDate={endDate}
              />
            </View>
            <View style={[styles.card, styles.flex_6]}>
              <View style={[styles.justifyCenter, styles.height40]}>
                <Text style={styles.labelTxt}>Program Progress Over Time</Text>
              </View>
              <ProgramProgressGraph
                sessions={filteredSessions}
                programs={programs}
                startDate={startDate}
                endDate={endDate}
              />
            </View>
          </View> */}
              <View style={[styles.paddingHorizontal]}>
                <Controller
                  control={control}
                  rules={{
                    required: 'This is required.',
                  }}
                  render={({field: {onChange, value}}) => (
                    <View style={[styles.flex]}>
                      <ProgramSelect
                        patientId={patient?.id}
                        selected={value}
                        selectProgram={onChange}
                      />
                    </View>
                  )}
                  name="programId"
                  defaultValue={filteredPrograms?.[0]?.id || ''}
                />
              </View>
              {selectedProgramId ? (
                <View style={[styles.paddingHorizontal, styles.alignSelfStart]}>
                  <DatePeriodInput name={'startDate'} watchedValue={client} />
                </View>
              ) : (
                <></>
              )}
              {selectedProgramId ? (
                <ProgramGraph
                  programId={selectedProgramId}
                  envs={clientEnvs}
                  startDate={startDate}
                  endDate={endDate}
                  collapsable={false}
                  showTable={true}
                />
              ) : (
                <View style={styles.emptyContainer}>
                  <Icon name="chart-areaspline" size={30} color={'grey'} />
                  <Text style={styles.emptyTitle}>No Program Selected</Text>
                </View>
              )}
            </>
          ) : (
            <View style={styles.emptyContainer}>
              <Icon name="account-supervisor" size={30} color={'grey'} />
              <Text style={styles.emptyTitle}>Please select a client</Text>
            </View>
          )}
        </ScrollView>
      )}
    </>
  );
};

export default compose(
  withDatabase,
  withObservables(['client'], ({client, database}: any) => {
    return {
      patient: database.get('patients').findAndObserve(client),
    };
  }),
  withObservables(['patient', 'filter'], ({patient, database, filter}: any) => {
    const queries = [];
    if (filter?.types && filter.types.length) {
      queries.push(Q.where('type', oneOf(filter.types)));
    }
    if (filter?.programs && filter.programs.length) {
      queries.push(Q.where('id', oneOf(filter.programs)));
    }
    return {
      filteredPrograms: database
        .get('programs')
        .query(
          Q.where('patient_id', patient.id),
          Q.where('state', Q.notEq('archived')),
          Q.where('deleted_at', null),
          ...queries,
        ),
    };
  }),
  withObservables(['patient'], ({patient}: any) => {
    return {
      clientPrograms: patient?.activePrograms || of([]),
      clientEnvs: patient?.activeEnvironmentalFactors || of([]),
      // allSessions: database
      //   .get('sessions')
      //   .query(Q.where('patient_id', patient?.id)),
      // filteredSessions: database
      //   .get('sessions')
      //   .query(
      //     Q.where('patient_id', patient?.id),
      //     Q.where('start_timestamp', Q.notEq(null)),
      //     Q.where('deleted_at', null),
      //     Q.sortBy('start_timestamp', Q.asc),
      //   ),
    };
  }),
  // withObservables(['clientPrograms'], ({clientPrograms, database}: any) => {
  //   const programIds = _.map(clientPrograms, 'id');
  //   return {
  //     sets: database
  //       .get('sets')
  //       .query(
  //         Q.where('program_id', Q.oneOf(programIds)),
  //         Q.where('deleted_at', null),
  //         Q.sortBy('start_timestamp', Q.asc),
  //       ),
  //   };
  // }),
  // withObservables(['sets'], ({sets, database}: any) => {
  //   const setIds = _.map(sets, 'id');
  //   return {
  //     events: database
  //       .get('events')
  //       .query(
  //         Q.where('set_id', Q.oneOf(setIds)),
  //         Q.where('deleted_at', null),
  //         Q.sortBy('created_at', Q.asc),
  //       ),
  //   };
  // }),
)(ClientReportBeta);
