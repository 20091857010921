import {appSchema, tableSchema} from '@nozbe/watermelondb';
import {timestamps} from './helpers';

export default appSchema({
  version: 85,
  tables: [
    tableSchema({
      name: 'instances',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'name', type: 'string'},
        {name: 'dba', type: 'string'},
        {name: 'address', type: 'string'},
        {name: 'reporting', type: 'boolean'},
        {name: 'billing_address', type: 'string'},
        {name: 'tin', type: 'string'},
        {name: 'npi', type: 'string'},
        {name: 'logo', type: 'string'},
        {name: 'note_template', type: 'string'},
        {name: 'account_number', type: 'string'},
        {name: 'actual_time', type: 'boolean'},
        {name: 'single_address', type: 'boolean'},
        {name: 'billing', type: 'boolean'},
        {name: 'taxonomy', type: 'string'},
        {name: 'use_client_identifier', type: 'boolean'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'roles',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'name', type: 'string'},
        {name: 'managed', type: 'boolean'},
        {name: 'organization_create', type: 'boolean'},
        {name: 'organization_edit', type: 'boolean'},
        {name: 'organization_delete', type: 'boolean'},
        {name: 'organization_view', type: 'boolean'},
        {name: 'organization_assigned_only', type: 'boolean'},
        {name: 'organization_diagnosis_create', type: 'boolean'},
        {name: 'organization_diagnosis_edit', type: 'boolean'},
        {name: 'organization_diagnosis_delete', type: 'boolean'},
        {name: 'organization_diagnosis_view', type: 'boolean'},
        {name: 'organization_diagnosis_assigned_only', type: 'boolean'},
        {name: 'organization_location_create', type: 'boolean'},
        {name: 'organization_location_edit', type: 'boolean'},
        {name: 'organization_location_delete', type: 'boolean'},
        {name: 'organization_location_view', type: 'boolean'},
        {name: 'organization_location_assigned_only', type: 'boolean'},
        {name: 'prompt_create', type: 'boolean'},
        {name: 'prompt_edit', type: 'boolean'},
        {name: 'prompt_delete', type: 'boolean'},
        {name: 'prompt_view', type: 'boolean'},
        {name: 'prompt_assigned_only', type: 'boolean'},
        {name: 'tag_create', type: 'boolean'},
        {name: 'tag_edit', type: 'boolean'},
        {name: 'tag_delete', type: 'boolean'},
        {name: 'tag_view', type: 'boolean'},
        {name: 'tag_assigned_only', type: 'boolean'},
        {name: 'role_create', type: 'boolean'},
        {name: 'role_edit', type: 'boolean'},
        {name: 'role_delete', type: 'boolean'},
        {name: 'role_view', type: 'boolean'},
        {name: 'role_assigned_only', type: 'boolean'},
        {name: 'appointment_create', type: 'boolean'},
        {name: 'appointment_edit', type: 'boolean'},
        {name: 'appointment_delete', type: 'boolean'},
        {name: 'appointment_view', type: 'boolean'},
        {name: 'appointment_assigned_only', type: 'boolean'},
        {name: 'patient_create', type: 'boolean'},
        {name: 'patient_edit', type: 'boolean'},
        {name: 'patient_delete', type: 'boolean'},
        {name: 'patient_view', type: 'boolean'},
        {name: 'patient_assigned_only', type: 'boolean'},
        {name: 'treatment_plan_create', type: 'boolean'},
        {name: 'treatment_plan_edit', type: 'boolean'},
        {name: 'treatment_plan_delete', type: 'boolean'},
        {name: 'treatment_plan_view', type: 'boolean'},
        {name: 'treatment_plan_assigned_only', type: 'boolean'},
        {name: 'authorization_create', type: 'boolean'},
        {name: 'authorization_edit', type: 'boolean'},
        {name: 'authorization_delete', type: 'boolean'},
        {name: 'authorization_view', type: 'boolean'},
        {name: 'authorization_assigned_only', type: 'boolean'},
        {name: 'environmental_factor_create', type: 'boolean'},
        {name: 'environmental_factor_edit', type: 'boolean'},
        {name: 'environmental_factor_delete', type: 'boolean'},
        {name: 'environmental_factor_view', type: 'boolean'},
        {name: 'environmental_factor_assigned_only', type: 'boolean'},
        {name: 'client_document_create', type: 'boolean'},
        {name: 'client_document_edit', type: 'boolean'},
        {name: 'client_document_delete', type: 'boolean'},
        {name: 'client_document_view', type: 'boolean'},
        {name: 'client_document_assigned_only', type: 'boolean'},
        {name: 'assigned_staff_create', type: 'boolean'},
        {name: 'assigned_staff_edit', type: 'boolean'},
        {name: 'assigned_staff_delete', type: 'boolean'},
        {name: 'assigned_staff_view', type: 'boolean'},
        {name: 'assigned_staff_assigned_only', type: 'boolean'},
        {name: 'staff_document_create', type: 'boolean'},
        {name: 'staff_document_edit', type: 'boolean'},
        {name: 'staff_document_delete', type: 'boolean'},
        {name: 'staff_document_view', type: 'boolean'},
        {name: 'staff_document_assigned_only', type: 'boolean'},
        {name: 'user_create', type: 'boolean'},
        {name: 'user_edit', type: 'boolean'},
        {name: 'user_delete', type: 'boolean'},
        {name: 'user_view', type: 'boolean'},
        {name: 'user_assigned_only', type: 'boolean'},
        {name: 'assigned_client_create', type: 'boolean'},
        {name: 'assigned_client_edit', type: 'boolean'},
        {name: 'assigned_client_delete', type: 'boolean'},
        {name: 'assigned_client_view', type: 'boolean'},
        {name: 'assigned_client_assigned_only', type: 'boolean'},
        {name: 'report_create', type: 'boolean'},
        {name: 'report_edit', type: 'boolean'},
        {name: 'report_delete', type: 'boolean'},
        {name: 'report_view', type: 'boolean'},
        {name: 'report_assigned_only', type: 'boolean'},
        {name: 'program_create', type: 'boolean'},
        {name: 'program_edit', type: 'boolean'},
        {name: 'program_delete', type: 'boolean'},
        {name: 'program_view', type: 'boolean'},
        {name: 'program_assigned_only', type: 'boolean'},
        {name: 'timesheet_create', type: 'boolean'},
        {name: 'timesheet_edit', type: 'boolean'},
        {name: 'timesheet_delete', type: 'boolean'},
        {name: 'timesheet_view', type: 'boolean'},
        {name: 'timesheet_assigned_only', type: 'boolean'},
        {name: 'note_template_create', type: 'boolean'},
        {name: 'note_template_edit', type: 'boolean'},
        {name: 'note_template_delete', type: 'boolean'},
        {name: 'note_template_view', type: 'boolean'},
        {name: 'note_template_assigned_only', type: 'boolean'},
        {name: 'client_history_create', type: 'boolean'},
        {name: 'client_history_edit', type: 'boolean'},
        {name: 'client_history_delete', type: 'boolean'},
        {name: 'client_history_view', type: 'boolean'},
        {name: 'client_history_assigned_only', type: 'boolean'},
        {name: 'staff_history_create', type: 'boolean'},
        {name: 'staff_history_edit', type: 'boolean'},
        {name: 'staff_history_delete', type: 'boolean'},
        {name: 'staff_history_view', type: 'boolean'},
        {name: 'staff_history_assigned_only', type: 'boolean'},
        {name: 'session_create', type: 'boolean'},
        {name: 'session_edit', type: 'boolean'},
        {name: 'session_delete', type: 'boolean'},
        {name: 'session_view', type: 'boolean'},
        {name: 'session_assigned_only', type: 'boolean'},
        {name: 'billing_create', type: 'boolean'},
        {name: 'billing_edit', type: 'boolean'},
        {name: 'billing_delete', type: 'boolean'},
        {name: 'billing_view', type: 'boolean'},
        {name: 'billing_assigned_only', type: 'boolean'},
        {name: 'billing_payer_create', type: 'boolean'},
        {name: 'billing_payer_edit', type: 'boolean'},
        {name: 'billing_payer_delete', type: 'boolean'},
        {name: 'billing_payer_view', type: 'boolean'},
        {name: 'billing_payer_assigned_only', type: 'boolean'},
        {name: 'billing_claim_create', type: 'boolean'},
        {name: 'billing_claim_edit', type: 'boolean'},
        {name: 'billing_claim_delete', type: 'boolean'},
        {name: 'billing_claim_view', type: 'boolean'},
        {name: 'billing_claim_assigned_only', type: 'boolean'},
        {name: 'audit_create', type: 'boolean'},
        {name: 'audit_edit', type: 'boolean'},
        {name: 'audit_delete', type: 'boolean'},
        {name: 'audit_view', type: 'boolean'},
        {name: 'audit_assigned_only', type: 'boolean'},
        {name: 'audit_session_create', type: 'boolean'},
        {name: 'audit_session_edit', type: 'boolean'},
        {name: 'audit_session_delete', type: 'boolean'},
        {name: 'audit_session_view', type: 'boolean'},
        {name: 'audit_session_assigned_only', type: 'boolean'},
        {name: 'progress_report_create', type: 'boolean'},
        {name: 'progress_report_edit', type: 'boolean'},
        {name: 'progress_report_delete', type: 'boolean'},
        {name: 'progress_report_view', type: 'boolean'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'users',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'identifier', type: 'string'},
        {name: 'first_name', type: 'string'},
        {name: 'middle_name', type: 'string'},
        {name: 'last_name', type: 'string'},
        {name: 'email', type: 'string'},
        {name: 'gender', type: 'string'},
        {name: 'birth_date', type: 'number'},
        {name: 'permission', type: 'string'},
        {name: 'position', type: 'string'},
        {name: 'mobile_phone', type: 'string'},
        {name: 'address', type: 'string'},
        {name: 'role', type: 'string'},
        {name: 'credential', type: 'string'},
        {name: 'credentials', type: 'string'},
        {name: 'modifier', type: 'string'},
        {name: 'license_number', type: 'string'},
        {name: 'state', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'patients',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'identifier', type: 'string'},
        {name: 'first_name', type: 'string'},
        {name: 'middle_name', type: 'string'},
        {name: 'last_name', type: 'string'},
        {name: 'gender', type: 'string'},
        {name: 'birth_date', type: 'number'},
        {name: 'ssn', type: 'string'},
        {name: 'address', type: 'string'},
        {name: 'start_date', type: 'number'},
        {name: 'diagnosis', type: 'string'},
        {name: 'diagnoses', type: 'string'},
        {name: 'state', type: 'string'},
        {name: 'staff_id', type: 'string'},
        {name: 'intake_date', type: 'number'},
        {name: 'primary_id', type: 'string'},
        {name: 'referring_id', type: 'string'},
        {name: 'rendering_id', type: 'string'},
        {name: 'kafka', type: 'number'},
        {name: 'same_as_primary', type: 'boolean'},
        {name: 'school_id', type: 'string'},
        {name: 'bill_by_id', type: 'string'},
        {name: 'bill_physician_id', type: 'string'},
        {name: 'bill_by_physician', type: 'boolean'},

        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'authorizations',
      columns: [
        {name: 'patient_id', type: 'string', isIndexed: true},
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'authorization_number', type: 'string'},
        {name: 'start_date', type: 'number'},
        {name: 'end_date', type: 'number'},
        {name: 'assessment', type: 'number'},
        {name: 'supporting_assessment', type: 'number'},
        {name: 'assessment_multi_tech', type: 'number'},
        {name: 'direct_therapy', type: 'number'},
        {name: 'direct_group_therapy', type: 'number'},
        {name: 'supervision', type: 'number'},
        {name: 'supervision_multi_tech', type: 'number'},
        {name: 'caregiver_guidance', type: 'number'},
        {name: 'caregiver_guidance_group', type: 'number'},
        {name: 'state', type: 'string'},

        {name: 'payer_id', type: 'string'},
        {name: 'cpt97151', type: 'number'},
        {name: 'cpt97152', type: 'number'},
        {name: 'cpt97153', type: 'number'},
        {name: 'cpt97154', type: 'number'},
        {name: 'cpt97155', type: 'number'},
        {name: 'cpt97156', type: 'number'},
        {name: 'cpt97157', type: 'number'},
        {name: 'cpt97158', type: 'number'},
        {name: 'cpt0373T', type: 'number'},
        {name: 'cpt0362T', type: 'number'},
        {name: 'cptT1024', type: 'number'},
        {name: 'cpt92507', type: 'number'},
        {name: 'cpt92508', type: 'number'},
        {name: 'cpt92526', type: 'number'},
        {name: 'cpt92609', type: 'number'},
        {name: 'cpt92522', type: 'number'},
        {name: 'cpt92523', type: 'number'},
        {name: 'cpt92610', type: 'number'},
        {name: 'cptH2019', type: 'number'},
        {name: 'cptT2024', type: 'number'},
        {name: 'cptH0032', type: 'number'},
        {name: 'cpt90791', type: 'number'},
        {name: 'cpt90837', type: 'number'},
        {name: 'cptH0031', type: 'number'},
        {name: 'cptH2012', type: 'number'},
        {name: 'cptS5111', type: 'number'},
        {name: 'cptH2014', type: 'number'},
        {name: 'cptH0046', type: 'number'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'environmental_factors',
      columns: [
        {name: 'patient_id', type: 'string', isIndexed: true},

        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'type', type: 'string'},
        {name: 'date', type: 'number'},
        {name: 'title', type: 'string'},
        {name: 'description', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'documents',
      columns: [
        {name: 'patient_id', type: 'string', isIndexed: true},
        {name: 'user_id', type: 'string', isIndexed: true},
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'assessment_id', type: 'string'},
        {name: 'document_type', type: 'string'},
        {name: 'intake_type', type: 'string'},
        {name: 'file_name', type: 'string'},
        {name: 'file_location', type: 'string'},
        {name: 'local_file_location', type: 'string'},
        {name: 'sync_status', type: 'string'},
        {name: 'starred', type: 'boolean'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'care_team_participants',
      columns: [
        {name: 'user_id', type: 'string', isIndexed: true},
        {name: 'patient_id', type: 'string', isIndexed: true},

        {name: '_partition', type: 'string', isIndexed: true},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'tags',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'tag_type', type: 'string'},
        {name: 'name', type: 'string'},
        {name: 'acronym', type: 'string'},
        {name: 'rank', type: 'string'},
        {name: 'enabled', type: 'boolean'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'prompts',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'prompt_type', type: 'string'},
        {name: 'name', type: 'string'},
        {name: 'acronym', type: 'string'},
        {name: 'rank', type: 'string'},
        {name: 'enabled', type: 'boolean'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'program_prompt',
      columns: [
        {name: 'program_id', type: 'string', isIndexed: true},
        {name: 'prompt_id', type: 'string', isIndexed: true},

        {name: '_partition', type: 'string', isIndexed: true},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'program_tag',
      columns: [
        {name: 'program_id', type: 'string', isIndexed: true},
        {name: 'tag_id', type: 'string', isIndexed: true},

        {name: '_partition', type: 'string', isIndexed: true},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'targets',
      columns: [
        {name: 'program_id', type: 'string', isIndexed: true},

        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'name', type: 'string'},
        {name: 'rank', type: 'string'},
        {name: 'enabled', type: 'boolean'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'programs',
      columns: [
        {name: 'program_id', type: 'string', isIndexed: true},
        {name: 'patient_id', type: 'string', isIndexed: true},

        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'type', type: 'string'},
        {name: 'method', type: 'string'},
        {name: 'unit', type: 'string'},
        {name: 'name', type: 'string'},
        {name: 'objective', type: 'string'},
        {name: 'stimulus_description', type: 'string'},
        {name: 'teaching_instructions', type: 'string'},
        {name: 'materials_needed', type: 'string'},
        {name: 'number_of_trials', type: 'number'},
        {name: 'interval', type: 'number'},
        {name: 'interval_automation', type: 'boolean'},
        {name: 'interval_occurs', type: 'number'},
        {name: 'interval_recurrences', type: 'number'},
        {name: 'intensity', type: 'boolean'},
        {name: 'baseline', type: 'boolean'},
        {name: 'baseline_probes', type: 'number'},
        {name: 'baseline_correct_probes', type: 'number'},
        {name: 'baseline_sessions', type: 'number'},
        {name: 'mastery_value', type: 'number'},
        {name: 'mastery_sessions', type: 'number'},
        {name: 'mastery_staff', type: 'boolean'},
        {name: 'mastery_staff_minimum', type: 'number'},
        {name: 'maintenance', type: 'boolean'},
        {name: 'maintenance_value', type: 'number'},
        {name: 'maintenance_sessions', type: 'number'},
        {name: 'maintenance_cadence', type: 'string'},
        {name: 'maintenance_cadence_weekly', type: 'string'},
        {name: 'maintenance_cadence_monthly', type: 'number'},
        {name: 'maintenance_allowance', type: 'number'},
        {name: 'adjustment', type: 'string'},
        {name: 'state', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'participants',
      columns: [
        {name: 'appointment_id', type: 'string', isIndexed: true},
        {name: 'user_id', type: 'string', isIndexed: true},
        {name: 'patient_id', type: 'string', isIndexed: true},
        {name: 'start_time', type: 'number'},
        {name: 'end_time', type: 'number'},

        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'billable', type: 'boolean'},
        {name: 'supervision', type: 'boolean'},
        {name: 'cpt', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'appointments',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'date', type: 'number'},
        {name: 'start_timestamp', type: 'number'},
        {name: 'end_timestamp', type: 'number'},
        {name: 'start', type: 'number'},
        {name: 'end', type: 'number'},
        {name: 'location', type: 'string'},
        {name: 'type', type: 'string'},
        {name: 'rrule', type: 'string'},
        {name: 'cancellation_reason', type: 'string'},
        {name: 'cancellation_more_info', type: 'string'},
        {name: 'note_template_id', type: 'string'},
        {name: 'address', type: 'string'},
        {name: 'custom_address', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'sessions',
      columns: [
        {name: 'appointment_id', type: 'string', isIndexed: true},
        {name: 'patient_id', type: 'string', isIndexed: true},
        {name: 'user_id', type: 'string', isIndexed: true},
        {name: 'claim_id', type: 'string'},
        {name: 'supervising_id', type: 'string'},
        {name: 'rendering_id', type: 'string'},

        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'type', type: 'string'},
        {name: 'note', type: 'string'},
        {name: 'location', type: 'string'},
        {name: 'cpt', type: 'string'},
        {name: 'address', type: 'string'},
        {name: 'custom_address', type: 'string'},
        {name: 'date', type: 'number', isOptional: true},
        {name: 'session_date', type: 'string', isOptional: true},
        {name: 'start_timestamp', type: 'number', isOptional: true},
        {name: 'end_timestamp', type: 'number', isOptional: true},
        {name: 'edited_start_timestamp', type: 'number', isOptional: true},
        {name: 'edited_end_timestamp', type: 'number', isOptional: true},
        {name: 'therapist_signature', type: 'string', isOptional: true},
        {name: 'therapist_signature_2', type: 'string', isOptional: true},
        {name: 'supervision_signature', type: 'string', isOptional: true},
        {name: 'parent_signature_1', type: 'string', isOptional: true},
        {name: 'parent_signature_2', type: 'string', isOptional: true},
        {name: 'attestation_timestamp', type: 'number', isOptional: true},
        {name: 'submission_timestamp', type: 'number', isOptional: true},
        {name: 'post_session_emotions', type: 'string'},
        {name: 'post_session_impressions', type: 'string'},
        {name: 'additional_fields', type: 'string'},
        {name: 'note_edited_by', type: 'string'},
        {name: 'note_edited_timestamp', type: 'number'},
        {name: 'note_template_id', type: 'string'},
        {name: 'note_template_version_id', type: 'string'},
        {name: 'show_authors_and_timestamps', type: 'boolean'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'session_programs',
      columns: [
        {name: 'program_id', type: 'string', isIndexed: true},
        {name: 'session_id', type: 'string', isIndexed: true},

        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'rank', type: 'string'},
        {name: 'enabled', type: 'boolean'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'session_targets',
      columns: [
        {name: 'session_program_id', type: 'string', isIndexed: true},
        {name: 'target_id', type: 'string', isIndexed: true},

        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'rank', type: 'string'},
        {name: 'enabled', type: 'boolean'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'sets',
      columns: [
        {name: 'session_id', type: 'string', isIndexed: true},
        {name: 'program_id', type: 'string', isIndexed: true},
        {name: 'target_id', type: 'string', isIndexed: true},

        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'start_timestamp', type: 'number', isOptional: true},
        {name: 'end_timestamp', type: 'number', isOptional: true},
        {name: 'prompts', type: 'string'},
        {name: 'intensity', type: 'string'},
        {name: 'number_of_trials', type: 'number'},
        {name: 'not_addressed_reason', type: 'string'},
        {name: 'not_addressed_description', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'events',
      columns: [
        {name: 'set_id', type: 'string', isIndexed: true},
        {name: 'target_id', type: 'string', isIndexed: true},

        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'value', type: 'string'},
        {name: 'prompts', type: 'string', isOptional: true},
        {name: 'intensity', type: 'string', isOptional: true},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'note_templates',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'notifications',
      columns: [
        {name: '_partition', type: 'string'},
        {name: 'user_id', type: 'string'},
        {name: 'subject_model', type: 'string'},
        {name: 'subject_id', type: 'string'},
        {name: 'title', type: 'string'},
        {name: 'body', type: 'string'},
        {name: 'read_at', type: 'number', isOptional: true},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'caregivers',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'patient_id', type: 'string', isIndexed: true},
        {name: 'first_name', type: 'string'},
        {name: 'last_name', type: 'string'},
        {name: 'relationship', type: 'string'},
        {name: 'address', type: 'string'},
        {name: 'work_address', type: 'string'},
        {name: 'email', type: 'string'},
        {name: 'mobile_phone', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'medications',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'patient_id', type: 'string', isIndexed: true},
        {name: 'medication', type: 'string'},
        {name: 'dosage', type: 'string'},
        {name: 'frequency', type: 'string'},
        {name: 'archived', type: 'boolean'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'diagnoses',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'patient_id', type: 'string', isIndexed: true},
        {name: 'instance_diagnosis_id', type: 'string', isIndexed: true},
        {name: 'diagnosis', type: 'string'},
        {name: 'severity', type: 'string'},
        {name: 'diagnosis_date', type: 'number'},
        {name: 'archived', type: 'boolean'},
        {name: 'rank', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'instance_diagnoses',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'instance_id', type: 'string', isIndexed: true},
        {name: 'diagnosis', type: 'string'},
        {name: 'name', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'note_template_versions',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'note_template_id', type: 'string', isIndexed: true},

        {name: 'managed', type: 'boolean'},
        {name: 'title', type: 'string'},
        {name: 'template', type: 'string'},
        {name: 'version', type: 'number'},

        {name: 'organization_information', type: 'boolean'},
        {name: 'organization_name', type: 'boolean'},
        {name: 'organization_logo', type: 'boolean'},
        {name: 'organization_address', type: 'boolean'},
        {name: 'organization_billing_address', type: 'boolean'},
        {name: 'organization_tin', type: 'boolean'},
        {name: 'organization_npi', type: 'boolean'},
        {name: 'organization_account_number', type: 'boolean'},

        {name: 'client_information', type: 'boolean'},
        {name: 'client_first_name', type: 'boolean'},
        {name: 'client_middle_name', type: 'boolean'},
        {name: 'client_last_name', type: 'boolean'},
        {name: 'client_gender', type: 'boolean'},
        {name: 'client_birth_date', type: 'boolean'},
        {name: 'client_age_in_months', type: 'boolean'},
        {name: 'client_ssn', type: 'boolean'},
        {name: 'client_diagnoses', type: 'boolean'},
        {name: 'client_assigned_staff', type: 'boolean'},
        {name: 'primary_member_id', type: 'boolean'},
        {name: 'secondary_member_id', type: 'boolean'},

        {name: 'session_address', type: 'boolean'},
        {name: 'session_information', type: 'boolean'},
        {name: 'session_staff', type: 'boolean'},
        {name: 'session_date_of_service', type: 'boolean'},
        {name: 'session_start_time', type: 'boolean'},
        {name: 'session_end_time', type: 'boolean'},
        {name: 'session_duration', type: 'boolean'},
        {name: 'session_hour_format', type: 'boolean'},
        {name: 'session_type', type: 'boolean'},
        {name: 'session_location', type: 'boolean'},
        {name: 'clinical_note', type: 'boolean'},
        {name: 'show_authors_and_timestamps', type: 'boolean'},
        {name: 'session_data', type: 'boolean'},
        {name: 'staff_npi', type: 'boolean'},
        {name: 'staff_license_number', type: 'boolean'},
        {name: 'session_units_of_service', type: 'boolean'},

        {name: 'skill', type: 'boolean'},
        {name: 'behavior', type: 'boolean'},
        {name: 'additional_fields', type: 'boolean'},
        {name: 'form_builder_values', type: 'string'},
        {name: 'signature', type: 'boolean'},
        {name: 'published_at', type: 'number'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'signatures',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'entity_id', type: 'string', isIndexed: true},
        {name: 'entity', type: 'string'},
        {name: 'signature', type: 'string'},
        {name: 'type', type: 'string'},
        {name: 'signatory', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'notes',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'entity_id', type: 'string'},
        {name: 'entity', type: 'string'},
        {name: 'description', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'claims',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'patient_id', type: 'string'},
        {name: 'status', type: 'string'},
        {name: 'box_8', type: 'string'},
        {name: 'box_9b', type: 'string'},
        {name: 'box_9c', type: 'string'},
        {name: 'box_10d', type: 'string'},
        {name: 'box_19', type: 'string'},
        {name: 'box_30', type: 'string'},
        {name: 'kafka', type: 'number'},
        {name: 'payload', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'claim_events',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'claim_id', type: 'string'},
        {name: 'inovalon_id', type: 'string'},
        {name: 'timestamp', type: 'number'},
        {name: 'type', type: 'string'},
        {name: 'description', type: 'string'},
        {name: 'user', type: 'string'},
        {name: 'details', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'service_lines',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'claim_id', type: 'string'},
        {name: 'session_id', type: 'string'},
        {name: 'user_id', type: 'string'},
        {name: 'start_date', type: 'number'},
        {name: 'end_date', type: 'number'},
        {name: 'cpt', type: 'string'},
        {name: 'description', type: 'string'},
        {name: 'modifier_1', type: 'string'},
        {name: 'modifier_2', type: 'string'},
        {name: 'modifier_3', type: 'string'},
        {name: 'modifier_4', type: 'string'},
        {name: 'units', type: 'string'},
        {name: 'amount', type: 'string'},
        {name: 'diagnosis_pointer', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'locations',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'instance_id', type: 'string'},
        {name: 'address', type: 'string'},
        {name: 'name', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'payers',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'name', type: 'string'},
        {name: 'payer_id', type: 'string'},
        {name: 'inovalon_id', type: 'number'},
        {name: 'legacy_id', type: 'string'},
        {name: 'staff_ids', type: 'boolean'},
        {name: 'enrollment', type: 'string'},
        {name: 'address', type: 'string'},
        {name: 'mobile_phone', type: 'string'},
        {name: 'taxonomy', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'payer_credential',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'credential_id', type: 'string', isIndexed: true},
        {name: 'payer_id', type: 'string', isIndexed: true},
        {name: 'legacy_id', type: 'string'},
        {name: 'taxonomy', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'payer_plans',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'payer_id', type: 'string', isIndexed: true},
        {name: 'name', type: 'string'},
        {name: 'fee_schedule', type: 'string'},
        {name: 'notes', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'insurances',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'member_id', type: 'string'},
        {name: 'group_number', type: 'string'},
        {name: 'patient_id', type: 'string'},
        {name: 'payer_id', type: 'string'},
        {name: 'plan_id', type: 'string'},
        {name: 'authorization_id', type: 'string'},
        {name: 'start_date', type: 'number'},
        {name: 'end_date', type: 'number'},
        {name: 'rank', type: 'string'},
        {name: 'subscriber', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'credentials',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'user_id', type: 'string'},
        {name: 'npi', type: 'string'},
        {name: 'tin', type: 'string'},
        {name: 'ssn', type: 'string'},
        {name: 'taxonomy', type: 'string'},
        {name: 'first_name', type: 'string'},
        {name: 'middle_name', type: 'string'},
        {name: 'last_name', type: 'string'},
        {name: 'phone_number', type: 'string'},
        {name: 'email', type: 'string'},
        {name: 'address', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'taggables',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'entity_id', type: 'string'},
        {name: 'entity', type: 'string'},
        {name: 'tag_id', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'remits',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'inovalon_id', type: 'string'},
        {name: 'payer_id', type: 'string'},
        {name: 'response', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'remit_claim',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'inovalon_id', type: 'string'},
        {name: 'claim_id', type: 'string'},
        {name: 'remit_id', type: 'string'},
        {name: 'response', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'transactions',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'remit_claim_id', type: 'string'},
        {name: 'service_line_id', type: 'string'},
        {name: 'response', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'assessments',
      columns: [
        {name: 'patient_id', type: 'string', isIndexed: true},
        {name: 'document_id', type: 'string', isIndexed: true},

        {name: '_partition', type: 'string'},
        {name: 'assessment_date', type: 'number'},
        {name: 'start_time', type: 'string'},
        {name: 'end_time', type: 'string'},
        {name: 'overview', type: 'string'},
        {name: 'assessor', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'progress_reports',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'patient_id', type: 'string', isIndexed: true},
        {name: 'authorization_id', type: 'string'},
        {name: 'type', type: 'string'},
        {name: 'start_date', type: 'number'},
        {name: 'end_date', type: 'number'},

        {name: 'caregiver_guidance_info', type: 'string'},
        {name: 'background_info', type: 'string'},
        {name: 'medical_info', type: 'string'},
        {name: 'behavior_intervention_plan', type: 'string'},
        {name: 'summary', type: 'string'},
        {name: 'crisis_plan', type: 'string'},
        {name: 'transition_plan', type: 'string'},
        {name: 'recommendations', type: 'string'},
        {name: 'therapist_signature', type: 'string'},
        {name: 'therapist_signature_2', type: 'string'},
        {name: 'parent_signature', type: 'string'},

        {name: 'caregiver_guidance_info_include', type: 'boolean'},
        {name: 'background_info_include', type: 'boolean'},
        {name: 'medical_info_include', type: 'boolean'},
        {name: 'behavior_intervention_plan_include', type: 'boolean'},
        {name: 'summary_include', type: 'boolean'},
        {name: 'crisis_plan_include', type: 'boolean'},
        {name: 'transition_plan_include', type: 'boolean'},
        {name: 'recommendations_include', type: 'boolean'},
        {name: 'signature_include', type: 'boolean'},

        {name: 'cpt97151', type: 'number'},
        {name: 'cpt97152', type: 'number'},
        {name: 'cpt97153', type: 'number'},
        {name: 'cpt97154', type: 'number'},
        {name: 'cpt97155', type: 'number'},
        {name: 'cpt97156', type: 'number'},
        {name: 'cpt97157', type: 'number'},
        {name: 'cpt97158', type: 'number'},
        {name: 'cpt0373T', type: 'number'},
        {name: 'cpt0362T', type: 'number'},
        {name: 'cptT1024', type: 'number'},
        {name: 'cpt92507', type: 'number'},
        {name: 'cpt92508', type: 'number'},
        {name: 'cpt92526', type: 'number'},
        {name: 'cpt92609', type: 'number'},
        {name: 'cpt92522', type: 'number'},
        {name: 'cpt92523', type: 'number'},
        {name: 'cpt92610', type: 'number'},
        {name: 'cptH2019', type: 'number'},
        {name: 'cptT2024', type: 'number'},
        {name: 'cptH0032', type: 'number'},
        {name: 'cpt90791', type: 'number'},
        {name: 'cpt90837', type: 'number'},
        {name: 'cptH0031', type: 'number'},
        {name: 'cptH2012', type: 'number'},
        {name: 'cptS5111', type: 'number'},
        {name: 'cptH2014', type: 'number'},
        {name: 'cptH0046', type: 'number'},

        {name: 'submitted_at', type: 'number'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'progress_report_program',
      columns: [
        {name: 'program_id', type: 'string', isIndexed: true},
        {name: 'progress_report_id', type: 'string', isIndexed: true},

        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'program_include', type: 'boolean'},
        {name: 'graph_include', type: 'boolean'},
        {name: 'start_date', type: 'number'},
        {name: 'end_date', type: 'number'},
        {name: 'program_info', type: 'string'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'progress_report_caregiver',
      columns: [
        {name: 'caregiver_id', type: 'string', isIndexed: true},
        {name: 'progress_report_id', type: 'string', isIndexed: true},

        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'caregiver_include', type: 'boolean'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'progress_report_assessment',
      columns: [
        {name: 'assessment_id', type: 'string', isIndexed: true},
        {name: 'progress_report_id', type: 'string', isIndexed: true},

        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'assessment_date_include', type: 'boolean'},
        {name: 'assessment_time_include', type: 'boolean'},
        {name: 'assessment_assessor_include', type: 'boolean'},
        {name: 'assessment_age_include', type: 'boolean'},
        ...timestamps,
      ],
    }),
    tableSchema({
      name: 'billing_entities',
      columns: [
        {name: '_partition', type: 'string', isIndexed: true},
        {name: 'name', type: 'string'},
        {name: 'dba', type: 'string'},
        {name: 'address', type: 'string'},
        {name: 'tin', type: 'string'},
        {name: 'npi', type: 'string'},
        {name: 'taxonomy', type: 'string'},
        ...timestamps,
      ],
    }),
  ],
});
